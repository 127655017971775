import React, {useEffect, useState} from "react";
import {useField} from "react-final-form";
import { TextInput } from "react-admin";
import { updateCompanyData } from "../actions/companyLookupAction";
import {fetchFromObject} from "../utils";
import useInputStyles from "../components/InputStyles";

const CompanyDataInput = ({fullWidth = true, ...props}) => {
    const classes = useInputStyles()

    const { input: { value, onChange } } = useField(props.source)
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        if (value) setInputValue(value)
        if (!value) onChange(value)
    }, [value, onChange])

    useEffect(() => {
        const newValue = fetchFromObject(props.companyData, props.source)
        if (!value && newValue !== value) onChange(newValue)
    }, [props.companyData, value])

    const handleChange = event => {
        const newValue = event.target.value

        props.dispatch(updateCompanyData({
            ...props.companyData,
            [props.source]: newValue
        }))

        onChange(event)
    }

    return (
        <TextInput
            fullWidth={fullWidth}
            variant="outlined"
            source={props.source}
            label={props.label}
            value={inputValue}
            helperText={false}
            InputProps={{classes: { input: classes.input }}}
            InputLabelProps={{shrink: true}}
            onChange={handleChange} />
    )
}

export default CompanyDataInput
