import React, {useState} from 'react';
import {List, Datagrid, TextField, BooleanField, Edit, DateField, Button, useRefresh, useLogout, TabbedForm, FormTab, ReferenceManyField, Pagination, NumberField, FunctionField, downloadCSV, Toolbar, useNotify} from 'react-admin';
import SearchFilter from "../components/searchFilter";
import UtcDateField from "../components/utcDateField";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import {makeStyles} from "@material-ui/styles";
import {httpClient, zeroPad} from "../helpers/helpers";
import * as Config from "../config";
import ExportButton from "../components/ExportButton";
import jsonExport from 'jsonexport/dist';
import UserComments from "./UserComments";

const amountOptions = { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 };
const percentOptions = { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 };

const useStyles = makeStyles({
    button: {
        padding: '0.75em',
        marginTop: '1em',
        marginBottom: '2em',
    },
    saveButton: {
        padding: '0.55em',
        paddingLeft: '1.25em',
        paddingRight: '1.25em',
    },
});

const BankAccountField = ({ record = {}, source }) => {
    const account = record[source].find(i => i.main) || {};

    return <span>{account.accountNumber}</span>;
};

export const CustomerList = props => (
    <List {...props} filters={<SearchFilter />} sort={{ field: 'registrationDate', order: 'DESC' }} bulkActionButtons={false} exporter={false}>
        <Datagrid rowClick="edit">
            <FunctionField render={record => record ? zeroPad(record.Id, 6) : ''} label="#" />
            <TextField source="contact.firstName" label="First name" sortBy="details.firstname" />
            <TextField source="contact.lastName" label="Last name" sortBy="details.lastname" />
            <FunctionField render={record => record && record.company ? record.company.name : '-'} label="Company name" />
            <TextField source="contact.telephone" label="Phone" sortBy="details.phone" />
            <TextField source="address.townOrSuburb" label="City" sortBy="details.city" />
            <TextField source="address.regionOrState" label="Country" sortBy="details.country.name" />
            <BankAccountField source="bankAccounts" label="Bank account" sortable={false} />
            <BooleanField source="status.confirmed" label="Confirmed" sortBy="details.userStatus.id" />
            <UtcDateField record={props.record} source="registrationDate" label="Registered" />
        </Datagrid>
    </List>
);

const CustomerEditToolbar = ({ props, record, onSave }) => {
    const classes = useStyles();

    return <Toolbar {...props}>
        <Button variant="contained" startIcon={<SaveIcon/>} label="Save" className={classes.saveButton} onClick={() => onSave(record)}/>;
    </Toolbar>;
};

const CustomerEditTitle = ({ record }) => {
    return <span>{record ? `${record.contact.firstName} ${record.contact.lastName}` : ''}</span>;
};

const CustomerConfirmButton = ({ record, onConfirm }) => {
    const classes = useStyles();

    if (record.status.confirmed) {
        return null;
    } else {
        return <Button variant="contained" startIcon={<CheckIcon/>} label="Confirm user" className={classes.button} onClick={onConfirm}/>;
    }
};

export const CustomerEdit = props => {
    const refresh = useRefresh();
    const logout = useLogout();
    const notify = useNotify();

    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState('');

    const handleConfirm = () => {
        async function confirmUser() {
            try {
                await httpClient(`${Config.ADMIN_API_URL}/customer/${props.id}/confirm`, {
                    method: 'put',
                    mode: 'cors',
                    cache: 'no-cache',
                });
                refresh();
            } catch (e) {
                if (e.status === 401) {
                    logout();
                }
                console.log(e.body || e);
            }
        }

        confirmUser();
    };

    const handleExport = () => {
        setLoading(true);

        async function exportFundsHistory() {
            try {
                let response = await httpClient(`${Config.ADMIN_API_URL}/user_funds_history/${props.id}?size=10000`);

                // Taken from `exporter` sample from: https://marmelab.com/react-admin/List.html#exporter
                const exportData = response.json.content.map(data => {
                    const { dateFormatted, type, value, availableFunds, accountValue } = data;
                    return {
                        date: dateFormatted,
                        type: type,
                        value: `${value}`,
                        availableFunds: `${availableFunds}`,
                        accountValue: `${accountValue}`,
                    };
                });

                // Params docs: https://github.com/kaue/jsonexport
                jsonExport(exportData, {
                    headers: ['date', 'type', 'value', 'availableFunds', 'accountValue'], // order fields in the export
                    rowDelimiter: ';',
                }, (err, csv) => {
                    downloadCSV(csv, `funds_history_${props.id}`);
                });
            } catch (e) {
                if (e.status === 401) {
                    logout();
                }
                console.log(e.body || e);
            }

            setLoading(false);
        }

        exportFundsHistory();
    }

    const onSave = record => {
        async function saveNotes() {
            try {
                await httpClient(`${Config.ADMIN_API_URL}/customer/${record.id}/notes`, {
                    method: 'put',
                    mode: 'cors',
                    cache: 'no-cache',
                    body: JSON.stringify({notes: notes}),
                });
                notify('Comments saved');
            } catch (e) {
                if (e.status === 401) {
                    await logout();
                }
                console.log(e.body || e);
            }
        }

        saveNotes();
    }

    const onCommentChange = comment => {
        setNotes(comment);
    }

    const companyName = record => {
        return record && record.company ? ` - ${record.company.name}` : '';
    }

    return (
        <Edit {...props} title={<CustomerEditTitle />}>
            <TabbedForm toolbar={<CustomerEditToolbar onSave={onSave}/>}>
                <FormTab label="Details">
                    <TextField disabled source="id" />
                    <FunctionField render={record => record ? `${record.investorType} ${companyName(record)}` : ''} label="Investor type" />
                    <FunctionField render={record => record ? `${record.contact.firstName} ${record.contact.lastName}` : ''} label="Name" />
                    <TextField disabled source="contact.telephone" label="Phone" />
                    <TextField disabled source="contact.email" label="Email" />
                    <TextField disabled source="taxNo" label="Tax number" />
                    <FunctionField render={record => record ? `${record.address.streetName}, ${record.address.townOrSuburb}` : ''} label="Address" />
                    <TextField disabled source="address.regionOrState" label="Country" />
                    <DateField disabled source="registrationDate" locales="sl-SI" options={{ year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }} />
                    <UserComments source="comments" value={notes} onChange={onCommentChange} />
                    <CustomerConfirmButton onConfirm={handleConfirm} />
                </FormTab>
                <FormTab label="Investments" path="investments">
                    <ReferenceManyField reference="user_investments" target="referencedUserId" addLabel={false} pagination={<Pagination />} fullWidth sort={{ field: 'dateStart', order: 'DESC' }}>
                        <Datagrid>
                            <TextField label="ID" source="investment.number" />
                            <UtcDateField label="Issued" source="dateStart" />
                            <UtcDateField label="Maturity date" source="investment.dateEnd" />
                            <UtcDateField label="End date" source="dateEnd" showMinimal={false}/>
                            <NumberField source="amount" options={ amountOptions } />
                            <NumberField label="Interest rate" source="investment.interestRate" options={ percentOptions } />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
                <FormTab label="Available funds history" path="funds">
                    <ExportButton onExport={handleExport} loading={loading} />
                    <ReferenceManyField reference="user_funds_history" target="referencedUserId" addLabel={false} pagination={<Pagination />} sort={{ field: null, order: null }} fullWidth>
                        <Datagrid>
                            <TextField label="Date" source="dateFormatted" sortable={false} />
                            <TextField source="type" sortable={false} />
                            <NumberField label="Amount" source="value" options={ amountOptions } sortable={false} />
                            <NumberField label="Available funds" source="availableFunds" options={ amountOptions } sortable={false} />
                            <NumberField label="Account value" source="accountValue" options={ amountOptions } sortable={false} />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};