import * as Config from '../config'
import {httpClient} from "../helpers/helpers";

export const AVAILABLE_REPORTS = 'AVAILABLE_REPORTS';
export const COMPANY_REPORT = 'COMPANY_REPORT';

export const updateAvailableReportsData = (reportsData) => ({
    type: AVAILABLE_REPORTS,
    payload: reportsData,
});

export const updateCompanyReportData = (reportData) => ({
    type: COMPANY_REPORT,
    payload: reportData,
});

export const reportsAction = (dispatch, id) => {
    httpClient(`${Config.API_URL}/v2/companies/availableReports/${id}`).then(res => {
        return res.json;
    }).then(reportsData => {
        dispatch(updateAvailableReportsData(reportsData));
    }).catch((err) => {
        console.log(err);

        dispatch(updateAvailableReportsData({}));
    });
};

export const reportAction = (dispatch, id, year, type) => {
    httpClient(`${Config.API_URL}/v2/companies/report/${id}?year=${year}&type=${type}`).then(res => {
        return res.json;
    }).then(reportData => {
        dispatch(updateCompanyReportData(reportData));
    }).catch((err) => {
        console.log(err);

        dispatch(updateCompanyReportData({}));
    });
};