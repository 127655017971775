import {fetchUtils} from "react-admin";
import {useRef} from "react";
import * as Config from "../config";
import {callApiWithAppCheck} from "../firebase";
import authProvider from "../authProvider";

//region Date helpers

export const dateFormatter = millis => {
    if (isNaN(millis) || millis === null) return '';
    const v = new Date(millis);
    if (!(v instanceof Date)) return '';
    const pad = '00';
    const yy = v.getUTCFullYear().toString();
    const mm = (v.getUTCMonth() + 1).toString();
    const dd = v.getUTCDate().toString();
    return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
};

export const dateParser = v => {
    // v is a string of "YYYY-MM-DD" format
    const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
    if (match === null) return;
    const d = new Date(Date.UTC(match[1], parseInt(match[2], 10) - 1, match[3]));
    if (isNaN(d)) return;
    return d;
};

export const sloDateParser = v => {
    // v is a string of "DD MM YY" format
    const match = /^(\d{1,2})[-.\/](\d{1,2})[-.\/](\d{2,4})$/gm.exec(v);
    if (match === null) return;
    const d = new Date(Date.UTC(match[3], parseInt(match[2], 10) - 1, match[1]));
    if (isNaN(d)) return;
    return d;
};

//endregion

//region Decimal helpers

export const properDecimalNumber = s => {
    if (isNumber(s)) return s;
    if (!isString(s)) return null;

    const dotCount = (s.match(/\./g)||[]).length
    const lastDot = s.lastIndexOf('.')

    const commaCount = (s.match(/,/g)||[]).length
    const lastComma = s.lastIndexOf(',')

    if (lastDot > -1 && lastDot < lastComma && commaCount > 1) return null
    if (lastComma > -1 && lastComma < lastDot && dotCount > 1) return null

    if (dotCount > 1) {
        const noDot = s.replace(/[.]+/g, '');
        return noDot.replace(/,+/g, '.');
    } else if (commaCount > 1) {
        return s.replace(/,+/g, '');
    } else if (lastDot > -1 && lastComma < lastDot) {
        return s.replace(/,+/g, '');
    } else if (lastComma > -1 && lastDot < lastComma) {
        const noDot = s.replace(/[.]+/g, '');
        return noDot.replace(/,+/g, '.');
    }

    return s;
}

export const decimalNumberFormatter = (i, decimalPlaces = 2) => {
    if (!isString(i) && !isNumber(i)) return i;

    if (isString(i)) {
        let s = i;

        const lastDot = s.lastIndexOf('.');
        const lastComma = s.lastIndexOf(',');

        if (lastDot > lastComma) {
            s = s.replace(/[,]+/g, '');
        } else if (lastDot < lastComma) {
            s = s.replace(/[.]+/g, '');
            s = s.replace(/[,]+/g, '.');
        }

        return formatMoney(Number(s), decimalPlaces, ',', '.');
    }

    return formatMoney(i, decimalPlaces, ',', '.');
};

const formatMoney = (number, decPlaces, decSep, thouSep) => {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;

    const sign = number < 0 ? "-" : "";
    const integerPart = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    const formattedIntegerPart = integerPart.replace(/[0-9](?=(?:[0-9]{3})+(?![0-9]))/g, "$&" + thouSep);
    const fractionalPart = decPlaces ? decSep + Math.abs(number - integerPart).toFixed(decPlaces).slice(2) : "";

    return sign + formattedIntegerPart + fractionalPart;
}

//endregion

//region Communication

export const sharedAccessTokenKey = '_sat';
export const permissionsKey = '_nsspm';

export const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    // add your own headers here
    options.headers.set('Authorization', `Bearer ${localStorage.getItem(sharedAccessTokenKey)}`);
    return fetchUtils.fetchJson(url, options);
};


export async function sharedFetch(url, options = {}) {
    await callApiWithAppCheck(null, options);

    return fetch(`${Config.SHARED_API_URL}/${url}`, options)
        .then(async (response) => {
            if (response.ok) {
                if (response.headers.get('content-type').includes('application/json')) return response.json()
                else return response
            }
            if (response.status === 401) return Promise.reject({
                status: 401,
                message: "Your session has expired. Please log in again."
            })
            if (response.headers.get('content-type').includes('application/json')) return Promise.reject({
                status: response.status,
                ...await response.json().catch(error => Promise.reject(error))
            })
            return response
        })
        .catch(error => {
            if (!error) {
                alert('An error occurred, please try again');
            } else if (error.status === 401) {
                authProvider.logout()
                window.location.reload()
                //userLogout("/");
            } else if (error.message || error.error) {
                console.error(error.message || error.error)
                alert("Error: " + (error.message || error.error))
            } else {
                console.error(error);
            }

            return Promise.reject(error);
        });
}

//endregion

//region General

const isString = s => typeof s === 'string' || s instanceof String;

const isNumber = s => typeof s === 'number' || s instanceof Number;

export const zeroPad = (num, places) => String(num).padStart(places, '0')

export const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
        if (htmlElRef.current) htmlElRef.current.focus();
    }
    return [htmlElRef, setFocus];
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

//endregion

// css helper
export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function parseDecimalDotNumberString(s) {
    return parseFloat(s.replaceAll(",", ""))
}

function parseDecimalCommaNumberString(s) {
    return parseFloat(s.replaceAll(".", "").replaceAll(",", "."))
}

export function parseNumberString(s) {
    s = "" + s
    const dotPos = s.indexOf("."),
        commaPos = s.indexOf(",")
    if (commaPos !== -1 && dotPos !== -1) {
        return commaPos < dotPos ? parseDecimalDotNumberString(s) : parseDecimalCommaNumberString(s)
    }
    if (commaPos !== -1) {
        const [a, b] = s.split(",")
        if (commaPos !== s.lastIndexOf(",") || (a.length < b.length && b.length === 3)) {
            // is probably a thousand's separator
            return parseFloat(s.replaceAll(",", ""))
        } else {
            return parseFloat(s.replaceAll(",", "."))
        }
    }
    if (dotPos !== -1) {
        const [a, b] = s.split(".")
        if (dotPos !== s.lastIndexOf(".") || (a.length < b.length && b.length === 3)) {
            // is probably a thousand's separator
            return parseFloat(s.replaceAll(".", ""))
        }
    }
    return parseFloat(s)
}
