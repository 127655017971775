import React, {useState} from "react";
import {Button, TextField} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useInputStyles from "./InputStyles";

const UserLookup = ({ onSearch, loading, ...props }) => {
    const classes = useInputStyles()

    const [searchData, setSearchData] = useState('');

    const handleChange = e => {
        setSearchData(e.target.value);
    };

    return (
        <TextField
            variant={props.variant || 'filled'}
            className={classes.textField}
            size="small"
            label="Search accounts"
            helperText={false}
            onChange={handleChange}
            value={searchData}
            InputProps={{
                classes: {
                    input: classes.input,
                    adornedEnd: classes.endAdornment,
                },
                endAdornment: (
                    <Button variant="contained" className={classes.search} disabled={loading} onClick={() => onSearch(searchData)}><SearchIcon /></Button>
                )
            }} />
    )
};

export default UserLookup;