import {fetchUtils} from "react-admin";
import {httpClient, permissionsKey, sharedAccessTokenKey} from "./helpers/helpers";
import * as Config from "./config";
import {callApiWithAppCheck, firebaseAuth} from "./firebase";
import {signInWithEmailAndPassword, signOut} from "firebase/auth"

const authClient = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    // add your own headers here
    options.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    options.headers.set('Authorization', 'Basic YWRtaW51aTphZG1pbnVpc2VjcmV0');
    options.method = 'post';
    options.mode = "cors";
    options.cache = "no-cache";

    await callApiWithAppCheck(options);

    return fetchUtils.fetchJson(url, options);
}

const loginBE = async ({username, password = null, idToken = null}) => {
    const data = new URLSearchParams();
    data.append("grant_type", "password");
    data.append("scope", "read+write");
    data.append("username", username);
    data.append("password", password || "<id_token>");
    data.append("id_token", idToken);

    return authClient(Config.API_URL + "/oauth/token", {body: data})
        .then(res => {
            const accessToken = res?.json?.access_token || null
            localStorage.setItem(sharedAccessTokenKey, accessToken);
            return httpClient(Config.ADMIN_API_URL + "/admin_users/permissions").then(res => ([accessToken, res.json]))
        })
        .then(([accessToken, permissions]) => {
            localStorage.setItem(permissionsKey, permissions);
            //return [accessToken, permissions]
        })
}

const logout = async () => {
    localStorage.removeItem(sharedAccessTokenKey);
    localStorage.removeItem(permissionsKey);
    return signOut(firebaseAuth);
}

export default {
    // called when user attempts to log in
    login: ({username, password, idToken}) => {
        if (username && password) {
            return signInWithEmailAndPassword(firebaseAuth, username, password)
                .then(userCredential => Promise.reject({code: 'auth/enroll-mfa', message: 'Must enroll MFA'}))
        } else if (username && idToken) {
            return loginBE({username, idToken: idToken})
        } else {
            return Promise.reject(new Error('Missing username'))
        }
    },
    // called when user clicks on the logout button
    logout: () => {
        return logout()
            .then(() => Promise.resolve())
            .catch(error => {
                console.log(error);
                return Promise.resolve();
            })
    },
    // called when the API returns an error
    checkError: ({status}) => {
        if (status === 401) {
            return logout()
                .then(() => Promise.reject())
                .catch(error => {
                    console.log(error);
                    return Promise.reject();
                })
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem(sharedAccessTokenKey)
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        const role = localStorage.getItem(permissionsKey);
        return role ? Promise.resolve(role) : Promise.reject();
    },
}
