import React, {useEffect, useState} from 'react';
import {decimalNumberFormatter, httpClient} from "../helpers/helpers";
import * as Config from "../config";
import {useLogout, useRefresh} from "react-admin";
import {StatsList} from "./StatsList";

const columnsYearly = [
    { id: 'year', label: 'Year', minWidth: 40 },
    { id: 'amountStarted', label: 'Investments started', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'amountEnded', label: 'Investments ended', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'diff', label: 'Diff', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'currentlyInvested', label: 'Invested at end of year', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
];

const columnsMonthly = [
    { id: 'year', label: 'Year', minWidth: 40 },
    { id: 'month', label: 'Month', minWidth: 40 },
    { id: 'monthName', label: 'Month name', minWidth: 70 },
    { id: 'amountStarted', label: 'Investments started', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'amountEnded', label: 'Investments ended', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'diff', label: 'Diff', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'currentlyInvested', label: 'Invested at end of month', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
];

async function userInvestmentsStats() {
    return httpClient(`${Config.ADMIN_API_URL}/user_investments_stats`
    ).then(data => {
        switch (data.status) {
            case 401: throw new Error("Session expired!");
            default: return data.json
        }
    }).catch(error => {
        return error;
    })
}

export const UserInvestmentsStatsList = () => {
    const refresh = useRefresh();
    const logout = useLogout();

    const [yearlyItems, setYearlyItems] = useState(undefined)
    const [monthlyItems, setMonthlyItems] = useState(undefined)

    useEffect(() => {
        if (yearlyItems === undefined) {
            userInvestmentsStats()
                .then(d => {
                    if (d instanceof Error) throw d;
                    setYearlyItems(d.yearly.reverse())
                    setMonthlyItems(d.monthly.reverse())
                    refresh();
                })
                .catch(e => {
                    if (e.status === 401) {
                        logout();
                    }
                    console.log(e.body || e);
                })
        }
    }, [yearlyItems, monthlyItems, refresh, logout]);

    return (
        <div>
            <StatsList columns={columnsYearly} items={yearlyItems} />
            <br />
            <StatsList columns={columnsMonthly} items={monthlyItems} />
        </div>
    );
}