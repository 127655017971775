import React, {useEffect, useState} from "react";
import {useField} from "react-final-form";
import {TextField} from "@material-ui/core";
import useInputStyles from "./InputStyles";
import {decimalNumberFormatter, properDecimalNumber} from "../helpers/helpers";

const AmountInput = ({ label, maxDecimals = 2, onChange = null, ...props }) => {
    const classes = useInputStyles()
    const {input: {value: fieldValue, onChange: onFieldValueChange}, meta} = useField(props.source)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        if (isEditing) return
        let newValue = decimalNumberFormatter(properDecimalNumber(fieldValue), maxDecimals)
        if (fieldValue === '') newValue = ''
        onFieldValueChange(newValue)
    }, [isEditing, fieldValue, maxDecimals, onFieldValueChange])

    const handleChange = event => {
        const formattedValue = event.target.value
        const amount = properDecimalNumber(formattedValue)

        if (amount === null) return;

        onFieldValueChange(event)
        onChange?.(amount)
    }

    const handleFocus = () => {
        setIsEditing(true)
    }

    const handleBlur = () => {
        setIsEditing(false)
    }

    return (
        <TextField
            variant={props.variant}
            className={classes.textField}
            size="small"
            label={label}
            helperText={meta.error || false}
            error={!!meta.error}
            inputRef={props.inputRef}
            value={fieldValue}
            InputProps={{ classes: { input: classes.input }}}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}/>
    )
}

export default AmountInput
