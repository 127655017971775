import {CashIcon, PencilIcon} from "@heroicons/react/solid";
import {classNames} from "../helpers/helpers";
import React, {useLayoutEffect, useRef, useState} from "react";

export default function TableView({
                                      columns,
                                      onLoadMore,
                                      data,
                                      selectionDisabled,
                                      paginationDisabled,
                                      onSelectionChange,
                                      hasMore,
                                      tableActions,
                                      rowActions
                                  }) {

    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])

    useLayoutEffect(() => {
        const isIndeterminate = selectedItems.length > 0 && selectedItems.length < data?.length
        setChecked(selectedItems.length === data?.length && selectedItems.length > 0)
        setIndeterminate(isIndeterminate)
        if (checkbox?.current) checkbox.current.indeterminate = isIndeterminate
        onSelectionChange && onSelectionChange(selectedItems)
    }, [checkbox, selectedItems, data])

    function toggleAll() {
        setSelectedItems(checked || indeterminate ? [] : (data || []))
        setChecked(!checked && !indeterminate && selectedItems.length > 0)
        setIndeterminate(false)
    }

    return (
        <div
            className="relative">
            {tableActions && selectedItems.length > 0 && (
                <div
                    className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                    <button
                        type="button"
                        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                    >
                        <CashIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>
                        Payout
                    </button>
                    {/*<button
                                            type="button"
                                            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                                        >
                                            Delete all
                                        </button>*/}
                </div>
            )}
            <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                    {!selectionDisabled && (
                        <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                            <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500 sm:left-6"
                                ref={checkbox}
                                checked={checked}
                                onChange={toggleAll}
                            />
                        </th>
                    )}
                    {columns.map(({label}, index) => (
                        <th key={label} scope="col"
                            className={classNames(
                                index === 0 ? selectionDisabled ? "px-4 pl-4 sm:pl-6 pr-3" : "min-w-[12rem] py-3.5 pr-3" : "px-3 py-3.5",
                                "truncate text-left text-sm font-semibold text-gray-900"
                            )}>
                            {label}
                        </th>
                    ))}
                    {rowActions && (
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Edit</span>
                        </th>
                    )}
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {data && data.map((item, index) => (
                    <tr key={index}
                        className={selectedItems.includes(item) ? 'bg-gray-50' : undefined}>
                        {!selectionDisabled && (
                            <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                                {selectedItems.includes(item) && (
                                    <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-600"/>
                                )}
                                <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500 sm:left-6"
                                    value={item.id}
                                    checked={selectedItems.includes(item)}
                                    onChange={e =>
                                        setSelectedItems(
                                            e.target.checked
                                                ? [...selectedItems, item]
                                                : selectedItems.filter(it => it !== item)
                                        )
                                    }
                                />
                            </td>
                        )}

                        {columns.map((col, i) => (
                            <td key={col.label}
                                className={classNames(
                                    i === 0 ? selectionDisabled ? "py-4 pl-4 sm:pl-6 pr-3 text-gray-500 whitespace-nowrap truncate font-medium text-gray-900" : (selectedItems.includes(item) ? 'py-4 pr-3 text-primary-600' : 'py-4 pr-3 font-medium text-gray-900') : "px-3 py-4 text-gray-500 whitespace-nowrap truncate",
                                )}>
                                {col.href ? (
                                    <a
                                        className={classNames(
                                            col.className || "",
                                            "p-0 text-sm hover:text-gray-900 underline",
                                        )}
                                        rel="noreferrer"
                                        target="_blank"
                                        href={col.href(item)}>
                                        {col.v(item)}
                                    </a>
                                ) : (
                                    <span
                                        className={classNames(
                                            col.className || "",
                                            "p-0 text-sm",
                                        )}>{col.v(item)}</span>
                                )}
                            </td>
                        ))}

                        {rowActions && (
                            <td className="relative py-3.5 pl-3 pr-4 sm:pr-4">
                                {rowActions.edit && (
                                    <button
                                        type="button"
                                        onClick={() => rowActions.edit(item)}
                                        className={classNames(
                                            "ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-gray-500 bg-gray-200 hover:bg-gray-300",
                                        )}
                                    >
                                        <span className="sr-only">Edit</span>
                                        <PencilIcon className="h-3 w-3" aria-hidden="true"/>
                                    </button>
                                )}
                                {/*<a
                                    href={"#/invoices/"}
                                    className={classNames(
                                        "ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                                        "text-gray-500 bg-gray-200 hover:bg-gray-300"
                                    )}
                                >
                                    <span className="sr-only">Connect</span>
                                    <LinkIcon className="h-3 w-3" aria-hidden="true"/>
                                </a>*/}
                            </td>
                        )}
                    </tr>
                ))}

                </tbody>
            </table>
            {!paginationDisabled && (
                <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                    aria-label="Pagination"
                >
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            {data && data.length > 0 ? (
                                <>
                                    Showing <span
                                    className="font-medium">{data.length} results</span>
                                </>
                            ) : (
                                <>
                                    Showing <span className="font-medium">no results</span>
                                </>
                            )}

                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {hasMore && (
                            <button
                                onClick={onLoadMore}
                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Load more
                            </button>
                        )}
                    </div>
                </nav>
            )}
        </div>
    )
}
