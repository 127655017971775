import React, {useCallback, useEffect, useState} from "react";
import {SelectInput} from 'react-admin';
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    textField: {
        marginTop: '0.5em',
        marginBottom: '0.5em',
    },
})

const UnknownOption = { id: 'UNKNOWN', name: ' - ' }

const CreditRankingOptions = [
    { ...UnknownOption },
    { id: 'AAA', name: 'AAA (99-100)' },
    { id: 'AA', name: 'AA (92-98)' },
    { id: 'A', name: 'A (81-91)' },
    { id: 'BBB', name: 'BBB (61-80)' },
    { id: 'BB', name: 'BB (39-60)' },
    { id: 'B', name: 'B (22-38)' },
    { id: 'CCC', name: 'CCC (8-21)' },
    { id: 'CC', name: 'CC (3-7)' },
    { id: 'C', name: 'C (1-2)' },
    { id: 'NO', name: 'NO' },
]

const CompanySizeOptions = [
    { ...UnknownOption },
    { id: 'MICRO', name: 'MICRO' },
    { id: 'SMALL', name: 'SMALL' },
    { id: 'MEDIUM', name: 'MEDIUM' },
    { id: 'LARGE', name: 'LARGE' },
]

const EmployeeCountOptions = [
    { ...UnknownOption },
    { id: 'MICRO', name: '1-10' },
    { id: 'SMALL', name: '11-50' },
    { id: 'MEDIUM', name: '51-250' },
    { id: 'LARGE', name: '>250' },
]

const TotalIncomeOptions = [
    { ...UnknownOption },
    { id: 'MICRO', name: '0-700.000 €' },
    { id: 'SMALL', name: '700.001-8.000.000 €' },
    { id: 'MEDIUM', name: '8.000.001-40.000.000 €' },
    { id: 'LARGE', name: '>40.000.000 €' },
]

const LanguageOptions = [
    { id: 'sl', name: 'Slovene' },
    { id: 'en', name: 'English' },
]

export const PartnerSinceInput = props => {

    const initialYear = 2010;

    const currentYear = () => {
        const now = new Date();
        return now.getFullYear();
    }

    const availableYears = (initialYear) => {
        const year = currentYear();
        const years = []

        for (let i = year; i >= initialYear; i--) {
            years.push({
                id: `${i}`,
                name: `${i}`
            })
        }

        return years;
    }

    return (
        <SelectInput variant={props.variant} source="partnerSince" helperText={false} optionValue="id" defaultValue={currentYear()} choices={availableYears(initialYear)} />
    );
}

export const CreditRankingInput = props => {
    const hasData = useCallback(() => {
        return props.record?.autoData?.hasCreditRankingData === true
    }, [props.record])

    const getData = useCallback(() => {
        return hasData() ? CreditRankingOptions.find(i => i.id === props.record?.creditRanking)?.name : ''
    }, [props.record, hasData])

    return (
        <OptionalSelectInput source="creditRanking" label="Credit ranking" choices={CreditRankingOptions} onHasData={hasData} onGetData={getData} {...props} />
    )
}

export const CompanySizeInput = props => {
    const hasData = useCallback(() => {
        return props.record?.autoData?.hasCompanySizeData === true
    }, [props.record])

    const getData = useCallback(() => {
        return hasData() ? CompanySizeOptions.find(i => i.id === props.record?.companySize)?.name : ''
    }, [props.record, hasData])

    return (
        <OptionalSelectInput source="companySize" label="Company size" choices={CompanySizeOptions} onHasData={hasData} onGetData={getData} {...props} />
    )
}

export const EmployeeCountInput = props => {
    const hasData = useCallback(() => {
        return props.record?.autoData?.hasEmployeeCountData === true
    }, [props.record])

    const getData = useCallback(() => {
        return hasData() ? EmployeeCountOptions.find(i => i.id === props.record?.employeeCount)?.name : ''
    }, [props.record, hasData])

    return (
        <OptionalSelectInput source="employeeCount" label="Employee count" choices={EmployeeCountOptions} onHasData={hasData} onGetData={getData} {...props} />
    )
}

export const TotalIncomeInput = props => {
    const hasData = useCallback(() => {
        return props.record?.autoData?.hasTotalIncomeData === true
    }, [props.record])

    const getData = useCallback(() => {
        return hasData() ? TotalIncomeOptions.find(i => i.id === props.record?.totalIncome)?.name : ''
    }, [props.record, hasData])

    return (
        <OptionalSelectInput source="totalIncome" label="Total income range" choices={TotalIncomeOptions} onHasData={hasData} onGetData={getData} {...props} />
    )
}

export const LanguageInput = (props) => (
    <SelectInput variant={props.variant} source={props.source} label={props.label} helperText={false} optionValue="id" defaultValue="sl" choices={LanguageOptions} />
);

const OptionalSelectInput = ({optionValue = 'id', defaultValue = UnknownOption.name, choices = [UnknownOption], onHasData = null, onGetData = null, ...props}) => {

    const classes = useStyles();
    const [editable, setEditable] = useState(false);
    const [value, setValue] = useState('');

    useEffect(() => {
        const hasData = onHasData?.() || false
        setEditable(!hasData)
        if (hasData) {
            const data = onGetData?.() || ''
            setValue(data)
        }
    }, [props.record, onHasData, onGetData])

    return (
        <>
            {editable
                ? <SelectInput variant={props.variant} source={props.source} label={props.label} helperText={false} optionValue={optionValue} defaultValue={defaultValue} choices={choices}/>
                : <TextField className={classes.textField} label={props.label} helperText={false} value={value} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true, disableUnderline: true }}/>
            }
        </>
    )
}
