export const PROMISSORY_NOTE_STATUS = {
    DRAFT: "Draft",
    PENDING_APPROVAL: "Pending approval",
    FINANCING_IN_PROGRESS: "Financing in progress",
    FINANCED: "Financed",
    PAID: "Paid",
    /*DELAYED: "Delayed",
    IN_RECOVERY: "In recovery",
    INSURANCE_CLAIM: "Insurance claim",
    REPAID: "Repaid",
    DECLINED: "Declined",*/
}

export const PROMISSORY_NOTE_STATUS_KEY = {
    DRAFT: "DRAFT",
    PENDING_APPROVAL: "PENDING_APPROVAL",
    FINANCING_IN_PROGRESS: "FINANCING_IN_PROGRESS",
    FINANCED: "FINANCED",
    PAID: "PAID",
    /*DELAYED: "DELAYED",
    IN_RECOVERY: "IN_RECOVERY",
    INSURANCE_CLAIM: "INSURANCE_CLAIM",
    REPAID: "REPAID",
    DECLINED: "DECLINED",
    DISCOUNTED: "DISCOUNTED",*/
}

export const PROMISSORY_NOTE_INVOICE_STATUS = {
    /*PENDING_APPROVAL: "Pending approval",
    APPROVED: "Approved",
    FINANCING_IN_PROGRESS: "Financing in progress",
    FINANCED: "Financed",
    PAID: "Paid",
    DELAYED: "Delayed",
    IN_RECOVERY: "In recovery",
    INSURANCE_CLAIM: "Insurance claim",
    REPAID: "Repaid",
    DECLINED: "Declined",
    DISCOUNTED: "Discounted",*/

    NA: "N/A", // not applicable - invoice is not being financed
    IN_FINANCING: "IN_FINANCING", // invoice is due sometime in the future
    SETTLED: "SETTLED", // financing has been settled, paid off

}

export const TYPE = {
    F: "Factoring",
    RF: "Reverse Factoring",
    IF: "International Factoring",
}


export const INVOICE_TYPE = {
    ISSUED: "Issued",
    RECEIVED: "Received",
}

export const QUEUE_STATUS = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    DECLINED: "DECLINED",
}

export const QUEUE_PROTECTION = {
    BG: "BG",
    PG: "PG",
}

export const FINANCING_SOURCE = {
    P2P: "p2p", // nekster p2p
    OC: "oc", // own capital
    EC: "ec", // eurocom
    PS: "ps", // prva skladi
    PPD: "ppd", // prva pokojninska družba
    POZ: "poz", // prva osebna zavarovalnica
}
