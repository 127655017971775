import React, {useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import useInputStyles from "./InputStyles";

const TextInput = ({label, value, disabled, readonly = false, input = null, ...props}) => {
    const classes = useInputStyles()

    useEffect(() => {
        if (value && input && value !== input.value) input.onChange(value)
    }, [value, input])

    return (
        <TextField
            variant={props.variant}
            className={classes.textField}
            size="small"
            disabled={disabled}
            inputRef={props.inputRef}
            value={value || input?.value || ''}
            label={label}
            helperText={false}
            InputProps={{ classes: { input: classes.input }, readOnly: readonly }}
            onChange={input?.onChange || props.onChange}
            {...input}/>
    )
}

export default TextInput
