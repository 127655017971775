import React, {useState} from 'react';
import {List, Datagrid, TextField, NumberField, Edit, TabbedForm, FormTab, TextInput, SelectInput, DateInput, ReferenceInput, Toolbar, SaveButton, ArrayField, FunctionField, Create, SimpleForm, Button, useRefresh, Filter, SearchInput, useNotify} from 'react-admin';
import {Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import AddIcon from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import AmountInput from "../components/AmountInput";
import LabeledDateInput from "../components/LabeledDateInput";
import UtcDateField from "../components/utcDateField";
import InvestmentNumberInput from "./InvestmentNumberInput";
import {ProtectionTypeInput} from "./ProtectionTypeInput";
import * as Config from '../config'
import {dateFormatter, httpClient, properDecimalNumber} from "../helpers/helpers";

const amountOptions = { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 };
const percentOptions = { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 };

const useStyles = makeStyles({
    tableCell: {
        maxWidth: '30em',
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    button: {
        padding: '0.5em',
    },
    icon: {
        width: '1em',
    },
    statuses: {
        width: '30em',
    }
});

const statuses = [
    { id: '0', name: 'Ongoing' },
    { id: '1', name: 'Paid' },
    { id: '2', name: 'Delayed' },
    { id: '3', name: 'In recovery' },
    { id: '4', name: 'Defaulted' },
    { id: '5', name: 'Insurance claim' },
    { id: '6', name: 'Repaid' },
    { id: '7', name: 'Extended' },
];

const defaultInvestmentType = 'F'

const warningInterestRate = 10
const maxInterestRate = 14

const validate = data => {
    const errors = {}

    if (!data.number) errors.number = 'Missing number'
    if (!data.totalAmount) errors.totalAmount = 'Missing total amount'

    if (!data.interestRate) errors.interestRate = 'Missing interest rate'
    else if (properDecimalNumber(data.interestRate) > maxInterestRate) errors.interestRate = `Value exceeds ${maxInterestRate}`

    return errors
}


// const ListActions = ({
//                          currentSort,
//                          className,
//                          resource,
//                          filters,
//                          displayedFilters,
//                          exporter, // you can hide ExportButton if exporter = (null || false)
//                          filterValues,
//                          permanentFilter,
//                          hasCreate, // you can hide CreateButton if hasCreate = false
//                          basePath,
//                          selectedIds,
//                          onUnselectItems,
//                          showFilter,
//                          maxResults,
//                          total,
//                          ...rest
//                      }) => (
//     <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
//         {filters && cloneElement(filters, {
//             resource,
//             showFilter,
//             displayedFilters,
//             filterValues,
//             context: 'button',
//         })}
//         <CreateButton basePath={basePath} />
//         <ExportButton
//             disabled={total === 0}
//             resource={resource}
//             sort={currentSort}
//             filter={{ ...filterValues, ...permanentFilter }}
//             exporter={exporter}
//             maxResults={maxResults}
//         />
//         {/* Add your custom actions */}
//         <Button
//             onClick={() => { alert('Your custom action'); }}
//             label="Show calendar"
//         >
//             <IconEvent />
//         </Button>
//     </TopToolbar>
// );
//
// ListActions.defaultProps = {
//     selectedIds: [],
//     onUnselectItems: () => null,
// };

const InvestmentFilters = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <DateInput source="date_start_from" />
        <DateInput source="date_start_to" />
        <DateInput source="date_end_from" />
        <DateInput source="date_end_to" />
        <SelectInput source="status" optionValue="name" choices={statuses} alwaysOn />
    </Filter>
);

export const InvestmentList = props => {
    const classes = useStyles();

    return (
        <List {...props} filters={<InvestmentFilters />} sort={{ field: 'dateStart', order: 'DESC' }} bulkActionButtons={false} exporter={false}>
            <Datagrid rowClick="edit">
                <TextField source="number" cellClassName={classes.tableCell} />
                <TextField source="originator.name" label="Partner" cellClassName={classes.tableCell} />
                <UtcDateField record={props.record} source="dateStart" cellClassName={classes.tableCell} />
                <UtcDateField record={props.record} source="dateEnd" cellClassName={classes.tableCell} />
                <NumberField source="totalAmount" options={ amountOptions } />
                <NumberField source="availableAmount" options={ amountOptions } />
                <NumberField source="interestRate" options={ percentOptions } />
                <TextField source="lastStatus" sortable={false} label="Status" />
            </Datagrid>
        </List>
    );
}

const InvestmentEditTitle = ({ record }) => {
    return <span>{record ? `Investment: ${record.number}` : ''}</span>;
};

const InvestmentEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const InvestmentStatusAddButton = ({ onAddStatus }) => {
    const classes = useStyles();

    return (
        <Button startIcon={<AddIcon />} label="New status" className={classes.button} onClick={onAddStatus} />
    );
};

export const InvestmentEdit = props => {
    const classes = useStyles();

    const refresh = useRefresh();
    const notify = useNotify()

    const [investmentType, setInvestmentType] = useState(null)

    const [statusDate, setStatusDate] = useState(dateFormatter(new Date()));
    const [statusType, setStatusType] = useState(0);

    const [showDialog, setShowDialog] = useState(false);

    const onAddStatus = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setShowDialog(false);

        const payload = {
            id: props.id,
            statusId: statusType,
            statusDate: statusDate
        };

        console.log(`Payload: ${payload}`);

        (async () => {
            try {
                let response = await httpClient(`${Config.ADMIN_API_URL}/investments/${props.id}/status`, {
                    method: 'post',
                    mode: 'cors',
                    cache: 'no-cache',
                    body: JSON.stringify(payload),
                });
                console.log(response.body);
                refresh();
            } catch (e) {
                console.log(e.body || e);
            }
        })()
    };

    const handleTypeChanged = event => {
        setInvestmentType(event.target.value)
    };

    const handleStatusDateChanged = event => {
        setStatusDate(event.target.value);
    };

    const handleStatusTypeChanged = event => {
        setStatusType(event.target.value);
    };

    const statusFormatter = statusId => {
        return statuses.find(x => x.id === statusId.toString()).name;
    };

    const transform = data => {
        data.totalAmount = properDecimalNumber(data.totalAmount);
        data.availableAmount = properDecimalNumber(data.availableAmount);
        data.interestRate = properDecimalNumber(data.interestRate);

        return data;
    };

    const handleInterestRateChange = value => {
        if (properDecimalNumber(value) > maxInterestRate) {
            notify(`Value exceeds ${maxInterestRate}`, 'warning')
        } else if (properDecimalNumber(value) > warningInterestRate) {
            notify(`Value exceeds ${warningInterestRate}`, 'info')
        }
    }

    return (
        <Edit {...props} title={<InvestmentEditTitle />} transform={transform}>
            <TabbedForm variant="outlined" toolbar={<InvestmentEditToolbar />} validate={validate}>
                <FormTab label="Investment">
                    <TextInput disabled source="id" helperText={false} />
                    <TextInput source="number" helperText={false} />
                    <LabeledDateInput source="dateStart" label="Date start" />
                    <LabeledDateInput source="dateEnd" label="Date end" />
                    <AmountInput source="totalAmount" label="Total amount" />
                    <AmountInput source="availableAmount" label="Available amount" />
                    <AmountInput source="interestRate" label="Interest rate" onChange={handleInterestRateChange} />
                    <SelectInput label="Originator" source="data.originatorName" helperText={false} optionValue="name" choices={[
                        { name: '-' },
                        { name: 'Nekster finance d.o.o.' },
                        { name: 'Achilles d.o.o.' },
                    ]} />
                    <ReferenceInput fullWidth label="Company" source="data.company.id" helperText={false} reference="investmentCompanies" sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                        <SelectInput optionText="name" options={{ fullWidth:true }} />
                    </ReferenceInput>
                    <SelectInput label="Type" source="data.type" helperText={false} optionValue="id" onChange={handleTypeChanged} choices={[
                        { id: '-', name: '-' },
                        { id: 'F', name: 'Factoring' },
                        { id: 'RF', name: 'Reverse factoring' },
                        { id: 'IF', name: 'International factoring' },
                        { id: 'ML', name: 'Micro loan' },
                        { id: 'L', name: 'Leasing' },
                    ]} />
                    <ProtectionTypeInput investmentType={investmentType} />
                </FormTab>
                <FormTab label="Status">
                    <ArrayField label={''} source="statuses" className={classes.statuses}>
                        <Datagrid>
                            <UtcDateField record={props.record} source="date" format={dateFormatter} />
                            <UtcDateField record={props.record} label="Added" source="dateEntered" format={dateFormatter} />
                            <FunctionField label="Name" render={record => `${statusFormatter(record.statusId)}`} />
                        </Datagrid>
                    </ArrayField>
                    <InvestmentStatusAddButton source="name" onAddStatus={onAddStatus} />
                    <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
                        <form onSubmit={handleSubmit}>
                            <DialogTitle>Set current status</DialogTitle>
                            <DialogContent>
                                <DateInput label="Date" source="statusDate" defaultValue={statusDate} onChange={handleStatusDateChanged} />
                                <br />
                                <SelectInput label="Status" source="statusType" optionValue="id" choices={statuses} value={statusType} onChange={handleStatusTypeChanged} />
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" startIcon={<AddIcon />} label="Add status" className={classes.button} type="submit" />
                                <Button label="ra.action.cancel" onClick={handleCloseClick}>
                                    <IconCancel />
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export const InvestmentCreate = props => {
    const [investmentType, setInvestmentType] = useState(defaultInvestmentType)
    const notify = useNotify()

    const transform = data => {
        data.totalAmount = properDecimalNumber(data.totalAmount);
        data.availableAmount = properDecimalNumber(data.availableAmount);
        data.interestRate = properDecimalNumber(data.interestRate);

        return ({
            ...data,
            number: data.number
        })
    }

    const handleInterestRateChange = value => {
        if (properDecimalNumber(value) > maxInterestRate) {
            notify(`Value exceeds ${maxInterestRate}`, 'warning')
        } else if (properDecimalNumber(value) > warningInterestRate) {
            notify(`Value exceeds ${warningInterestRate}`, 'info')
        }
    }

    const handleTypeChanged = event => {
        setInvestmentType(event.target.value)
    }

    return (
        <Create {...props} transform={transform}>
            <SimpleForm variant="outlined" validate={validate}>
                <InvestmentNumberInput source="number" label="Number" />
                <LabeledDateInput source="dateStart" label="Date start" />
                <LabeledDateInput source="dateEnd" label="Date end" />
                <AmountInput source="totalAmount" label="Total amount" />
                <AmountInput source="availableAmount" label="Available amount" />
                <AmountInput source="interestRate" label="Interest rate" onChange={handleInterestRateChange} />
                <SelectInput label="Originator" source="data.originatorName" helperText={false} optionValue="name" defaultValue="Nekster finance d.o.o." choices={[
                    { name: '-' },
                    { name: 'Nekster finance d.o.o.' },
                ]} />
                <ReferenceInput fullWidth label="Company" source="data.company.id" helperText={false} reference="investmentCompanies" sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                    <SelectInput optionText="name" options={{ fullWidth:true }} />
                </ReferenceInput>
                <SelectInput label="Type" source="data.type" helperText={false} optionValue="id" defaultValue={defaultInvestmentType} onChange={handleTypeChanged} choices={[
                    { id: '-', name: '-' },
                    { id: 'F', name: 'Factoring' },
                    { id: 'RF', name: 'Reverse factoring' },
                    { id: 'IF', name: 'International factoring' },
                    { id: 'ML', name: 'Micro loan' },
                    { id: 'L', name: 'Leasing' },
                ]} />
                <ProtectionTypeInput investmentType={investmentType} />
            </SimpleForm>
        </Create>
    );
};
