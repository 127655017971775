import React from "react";
import PropTypes from 'prop-types';
import {useAuthenticated} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {InvestorCountList} from "./InvestorCountList";
import {FundsStatsList} from "./FundsStatsList";
import {DailySettlementsList} from "./DailySettlementsList";
import {InterestsStatsList} from "./InterestsStatsList";
import {InvestmentsStatsList} from "./InvestmentsStatsList";
import {UserInvestmentsStatsList} from "./UserInvestmentsStatsList";
import {VERSION} from "../config";

// const TestElement = ({test, isShown = true}) => {
//     return (
//         isShown ? <h1>{test}</h1> : null
//     );
// };
//
// const TestButtonTitle = ({title = ''}) => {
//     return (
//         <span>{title}</span>
//     )
// };
//
// const TestButton = ({onClick, children}) => {
//     return (
//         <button onClick={onClick} type="button">{children}</button>
//     )
// };

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
            {value === index && (
                <Box p={3}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const Dashboard = () => {
    useAuthenticated();

    // const [isShown, setShown] = useState(true);
    const [value, setValue] = React.useState(0);

    // const toggleShow = () => {
    //     setShown(!isShown)
    // };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <br />
            <Card position="static" color="inherit" variant="outlined">
                <CardHeader title={"Dashboard"} subheader={"v" + VERSION}/>
            </Card>
            <br />
            <Card>
                <CardHeader title="Latest stats"/>
                <CardContent>
                    <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
                        <Tab label="Users" />
                        <Tab label="Funds" />
                        <Tab label="Daily settlements" />
                        <Tab label="Interests" />
                        <Tab label="Investments" />
                        <Tab label="User investments" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        {/*<TestElement test="KRNEKI" isShown={isShown} />*/}
                        {/*<TestButton onClick={toggleShow}><TestButtonTitle title={isShown ? 'Hide' : 'Show'}/></TestButton>*/}
                        <InvestorCountList />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <FundsStatsList />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <DailySettlementsList />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <InterestsStatsList />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <InvestmentsStatsList />
                    </TabPanel>
                   <TabPanel value={value} index={5}>
                        <UserInvestmentsStatsList />
                    </TabPanel>
                </CardContent>
            </Card>
        </>
    );
}

export default Dashboard
