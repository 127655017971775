import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Combobox, Dialog, Transition} from '@headlessui/react'
import {classNames, httpClient} from "../helpers/helpers";
import {CheckIcon, SelectorIcon} from "@heroicons/react/solid";
import Spinner from "../components/spinner";
import {ADMIN_API_URL} from "../config";
import {useLogout} from "react-admin";

export default function ViewPartnerDialog({data, open, setOpen}) {
    const cancelButtonRef = useRef(null)

    const logout = useLogout()

    const [query, setQuery] = useState('')
    const [customName, setCustomName] = useState('')
    const [selectedItem, setSelectedItem] = useState(null)
    const [isSearching, setIsSearching] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [companies, setCompanies] = useState([])


    async function fetchCompanies() {
        return httpClient(`${ADMIN_API_URL}/investmentCompanies?page=${0}&size=${1000}&sort=name,ASC`).then(res => {
            //console.log(res.json.content)
            setCompanies(res.json.content)
        }).catch(e => {
            if (e.status === 401) {
                logout();
            }
        })
    }

    useEffect(() => {
        if (open) {
            fetchCompanies()
        }
    }, [open])

    useEffect(() => {
        setIsSearching(true)
        const delayDebounceFn = setTimeout(() => {
            //console.log(query)
            // Send Axios request here

            setIsSearching(false)
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [query])

    function onSelectionChange(e) {
        setCustomName(e.target.value)
        setQuery(e.target.value)
    }

    function onSelectedItem(item) {
        setSelectedItem(item)
        /*if (!item?.isCustom) {
            setIsEditingAllowed(false)
            setCustomName(item.name)
            setTaxNo(item.taxNumber)
            setAddress(item.address)
            setPostalCode(item.postCode)
            setCity(item.city)
            setCountry(item.country?.name)
            setContactPerson("")
            setContactTitle("")
            setContactPhone("")
        } else {
            setIsEditingAllowed(true)
            setTaxNo("")
            setAddress("")
            setPostalCode("")
            setCity("")
            setCountry("")
            setContactPerson("")
            setContactTitle("")
            setContactPhone("")
        }*/
    }

    function onSubmit(e) {
        e.preventDefault()

        setIsLoading(true)

        const obj = {
            vatNo: selectedItem?.taxNumber,
        }

        console.log(obj)
        /*try {
            // TODO: call connect partner API instead
            sharedFetch(`admin/organizations`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
                },
                body: JSON.stringify(obj),
            })
                .then(res => res.json())
                .then((resData) => {
                    console.log(resData)
                    setIsLoading(false)
                    setOpen(false)
                    //if (resData?.number) setDocumentNo(resData.number)
                    //if (resData?.results) setInvoiceRows(resData.results)
                    //setIsLoading(false)
                })
        } catch (e) {
            setIsLoading(false)
        }*/

        setIsLoading(false)
        setOpen(false)

        /*sharedApi.post(`/organizations/${activeOrganizationId}/partners`, obj)
            .then(res => {
                console.log("data", res.data)
                setOpen(false)
            })
            .catch(error => {
                // TODO: handle error
                console.log("error", error)
            })*/
        /*sharedFetch(`admin/organizations/${id}/partners`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
            },
        })
            .then(res => res.json())
            .then((resData) => {
                console.log(resData)
                //if (resData?.number) setDocumentNo(resData.number)
                if (resData?.results) setInvoiceRows(resData.results)
                //setIsLoading(false)
            })*/
    }

    const filteredCompanies =
        query === ''
            ? companies
            : companies.filter((item) =>
                ((item.shortName || item.name) + " " + item.taxNumber)
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                as="form"
                                onSubmit={onSubmit}
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        {/*<div
                                            className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600"
                                                                     aria-hidden="true"/>
                                        </div>*/}
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3"
                                                          className="text-lg font-medium leading-6 text-gray-900">
                                                {data?.name}
                                            </Dialog.Title>
                                            {!data?.oid && (
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        This partner has not yet been connected to an organization.
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                                        <div className="sm:col-span-6">
                                            <Combobox value={selectedItem} onChange={setSelectedItem}>
                                                <div className="relative mt-1">
                                                    <Combobox.Label className="block text-sm font-medium text-gray-700">Company
                                                        or organization name</Combobox.Label>
                                                    <div
                                                        className="relative mt-1 w-full bg-white border border-gray-300 rounded-md shadow-sm text-left overflow-hidden cursor-default focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
                                                        <Combobox.Input
                                                            autoComplete="off"
                                                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                            displayValue={(item) => (item?.shortName || item?.name || "")}
                                                            onChange={(event) => setQuery(event.target.value)}
                                                        />
                                                        <Combobox.Button
                                                            className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <SelectorIcon
                                                                className="h-5 w-5 text-gray-400"
                                                                aria-hidden="true"
                                                            />
                                                        </Combobox.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                        afterLeave={() => setQuery('')}
                                                    >
                                                        <Combobox.Options
                                                            className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                            {filteredCompanies.length === 0 && query !== '' ? (
                                                                <div
                                                                    className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                                    Nothing found.
                                                                </div>
                                                            ) : (
                                                                filteredCompanies.map((item) => (
                                                                    <Combobox.Option
                                                                        key={item.taxNumber}
                                                                        className={({active}) =>
                                                                            classNames(
                                                                                active ? 'text-white bg-gray-600' : 'text-gray-900',
                                                                                'cursor-default select-none relative py-2 pl-3 pr-9 pl-8'
                                                                            )
                                                                        }
                                                                        value={item}
                                                                    >
                                                                        {({selected, active}) => (
                                                                            <>
                                                                                <div className="flex">
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                            {item.shortName || item.name}
                          </span>
                                                                                    <span
                                                                                        className={classNames(active ? 'text-gray-200' : 'text-gray-500', 'ml-2 truncate')}>
                            {item.taxNumber}
                          </span>
                                                                                </div>

                                                                                {selected ? (
                                                                                    <span
                                                                                        className={classNames(
                                                                                            active ? 'text-white' : 'text-gray-600',
                                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                        )}
                                                                                    >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Combobox.Option>
                                                                ))
                                                            )}
                                                        </Combobox.Options>
                                                    </Transition>
                                                </div>
                                            </Combobox>
                                            {!data?.oid && (
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        Select an existing organization
                                                    </p>
                                                </div>
                                            )}
                                        </div>


                                    </div>

                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="submit"
                                        disabled={isLoading}
                                        className="inline-flex w-full justify-center items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                    >
                                        {!data?.oid ? "Connect" : "Update"}
                                        {isLoading && <Spinner button/>}
                                    </button>
                                    <button
                                        type="button"
                                        disabled={isLoading}
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
