import * as Config from "./config";
import {httpClient} from "./helpers/helpers";

export default {
    getList: (resource, params) => {
        function getFilter(filter) {
            if (!Object.keys(params.filter).length) return '';

            return '&' + Object.keys(params.filter).map(function(key) {
                return key + '=' + encodeURIComponent(params.filter[key]);
            }).join('&')
        }

        const filter = getFilter(params.filter);
        const limit = params.pagination.perPage;
        const offset = params.pagination.page - 1;
        const sort = params.sort.field ? `&sort=${params.sort.field}` : '';
        const direction = sort && params.sort.order ? `,${params.sort.order}` : '';
        const url = `${Config.ADMIN_API_URL}/${resource}?page=${offset}&size=${limit}${sort}${direction}${filter}`;

        return httpClient(url).then(({ json }) => {
            let data = json.content || json;
            if (data.length && !data[0].hasOwnProperty('id')) {
                data = data.map(obj => ({ ...obj, id: obj.Id }));
            }

            return ({
                data: data,
                total: json.totalElements || data.length,
            })
        })
    },

    getOne: (resource, params) => {
        return httpClient(Config.ADMIN_API_URL + "/" + resource + "/" + params.id).then(function ({ json }) {
            let data = json;
            if (!data.hasOwnProperty('id')) {
                data = { ...data, id: data.Id };
            }

            return ({
                data: data,
            });
        });
    },

    getMany: (resource, params) => {
        return httpClient(Config.ADMIN_API_URL + "/" + resource + "/" + params.ids[params.ids.length - 1]).then(function ({ json }) {
            let data = json;
            if (!data.hasOwnProperty('id')) {
                data = data.map(obj => ({ ...obj, id: obj.Id }));
            }

            return ({
                data: [ data ],
            });
        });
    },

    getManyReference: (resource, params) => {
        const id = params.id;
        const limit = params.pagination.perPage;
        const offset = params.pagination.page - 1;
        const sort = params.sort.field ? `&sort=${params.sort.field}` : '';
        const direction = sort && params.sort.order ? `,${params.sort.order}` : '';
        const url = `${Config.ADMIN_API_URL}/${resource}/${id}?page=${offset}&size=${limit}${sort}${direction}`;

        return httpClient(url).then(({ json }) => {
            let data = json.content || json;
            if (data.length && !data[0].hasOwnProperty('id')) {
                data = data.map(obj => ({ ...obj, id: obj.Id }));
            }

            return ({
                data: data,
                total: json.totalElements || data.length,
            })
        })
    },

    update: (resource, params) => {
        const resourceId = params.data.taxNumber || params.data.id;

        return httpClient(`${Config.ADMIN_API_URL}/${resource}/${resourceId}`, {
            method: 'put',
            mode: 'cors',
            cache: 'no-cache',
            body: JSON.stringify(params.data),
        }).then(function ({ json }) {
            let data = json;
            if (!data.hasOwnProperty('id')) {
                data = { ...data, id: data.Id };
            }

            return ({
                data: data,
            });
        });
    },

    updateMany: (resource, params) => {
        console.log(`updateMany - resource: ${resource}  params: ${params}`);
        return Promise.reject();
    },

    create: (resource, params) => {
        const resourceId = params.data.taxNumber || '' ;
        const method = params.data.taxNumber ? 'put' : 'post';

        return httpClient(`${Config.ADMIN_API_URL}/${resource}/${resourceId}`, {
            method: method,
            mode: 'cors',
            cache: 'no-cache',
            body: JSON.stringify(params.data),
        }).then(function ({ json }) {
            let data = json;
            if (!data.hasOwnProperty('id')) {
                data = { ...data, id: data.Id };
            }

            return ({
                data: data,
            });
        });
    },

    delete: (resource, params) => {
        return httpClient(`${Config.ADMIN_API_URL}/${resource}/${params.id}`, {
            method: 'delete',
            mode: 'cors',
            cache: 'no-cache',
        }).then(function ({ json }) {
            return ({
                data: json,
            });
        });
    },

    deleteMany: (resource, params) => {
        console.log(`deleteMany - resource: ${resource}  params: ${params}`);
        return Promise.reject();
    },
};
