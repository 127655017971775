import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";

export const EMAIL_FACTORING_DRAFT_ASSIGNEE = "EMAIL_FACTORING_DRAFT_ASSIGNEE",
    EMAIL_REVERSE_FACTORING_DRAFT_DEBTOR = "EMAIL_REVERSE_FACTORING_DRAFT_DEBTOR"


export const templates = {
    "": "None",
    "EMAIL_FACTORING_DRAFT_ASSIGNEE": "Email draft (factoring) to assignee",
    "EMAIL_REVERSE_FACTORING_DRAFT_DEBTOR": "Email draft (reverse factoring) to debtor",
}

export default function MaxDialog({open, setOpen, defaultPaidAmount, onSubmit}) {
    const cancelButtonRef = useRef(null)

    const today = new Date()
    const [paidDate, setPaidDate] = useState("")
    const [paidAmount, setPaidAmount] = useState("")

    useEffect(() => {
        if (open) {
            setPaidDate(today.toISOString().split("T")[0])
            setPaidAmount(defaultPaidAmount || "0")
        }
    }, [open])

    function onBeforeSubmit(e) {
        e.preventDefault()
        setOpen(false)
        onSubmit && onSubmit({paidAmount, paidAt: paidDate})
    }

    return (
        <Transition.Root show={!!open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                as="form"
                                onSubmit={onBeforeSubmit}
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        {/*<div
                                            className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600"
                                                                     aria-hidden="true"/>
                                        </div>*/}
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3"
                                                          className="text-lg font-medium leading-6 text-gray-900">
                                                Add payment
                                            </Dialog.Title>
                                            {/*<div className="mt-2">
                                                <p className="text-sm text-gray-500">

                                                </p>
                                            </div>*/}
                                        </div>
                                    </div>

                                    <div className="mt-6">
                                        <label htmlFor="paidAt" className="block text-sm font-medium text-gray-700">
                                            Paid at date
                                        </label>
                                        <input
                                            type="date"
                                            name="paidAt"
                                            id="paidAt"
                                            value={paidDate}
                                            onChange={e => setPaidDate(e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 py-2 px-3 text-base focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                                        />
                                    </div>

                                    <div className="mt-6">
                                        <label htmlFor="paidAmount" className="block text-sm font-medium text-gray-700">
                                            Paid amount
                                        </label>
                                        <input
                                            type="number"
                                            name="paidAmount"
                                            id="paidAmount"
                                            step={0.01}
                                            value={paidAmount}
                                            onChange={e => setPaidAmount(e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 py-2 px-3 text-base focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                                        />
                                    </div>

                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="submit"
                                        className="inline-flex w-full justify-center items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
