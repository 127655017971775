import React from 'react';
import {List, Datagrid, TextField, BooleanField, DateField} from 'react-admin';

const NotificationsListTitle = () => {
    return <span>Notification queue</span>;
};

export const NotificationsList = props => (
    <List {...props} title={<NotificationsListTitle />} sort={{ field: 'dateProcessed', order: 'DESC' }} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <TextField source="userId" label="User ID" />
            <TextField source="type" />
            <TextField source="transactionId" label="Transaction ID" />
            <BooleanField source="sendingAllowed" />
            <BooleanField source="sent" />
            <DateField source="dateProcessed" locales="sl-SI" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }} />
        </Datagrid>
    </List>
);