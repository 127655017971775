import React, {useCallback, useEffect, useState} from "react";
import {Datagrid, List, TextField, BooleanField, Edit, SimpleForm, TextInput, Button, FunctionField, Filter, SearchInput, SelectInput} from "react-admin";
import LabeledDateInput from "../components/LabeledDateInput";
import DownloadIcon from "@material-ui/icons/SaveAlt";
import {makeStyles} from "@material-ui/styles";
import {imageDownloadAction} from "../actions/imageDownloadAction";
import useInputStyles from "../components/InputStyles";

const useStyles = makeStyles({
    button: {
        padding: '0.5em',
    },
    icon: {
        width: '1em',
    },
    downloadRow: {
        marginTop: '0.25em',
        marginBottom: '0.25em',
    },
    smallText: {
        marginTop: '0.5em',
        fontFamily: 'sans-serif',
        fontSize: '0.75em',
        color: 'rgba(0, 0, 0, 0.5)'
    },
    label: {
        display: 'inline-block',
        minWidth: '10em',
        marginRight: '1em',
        fontFamily: 'sans-serif'
    },
});

const statuses = [
    { id: '0', name: 'Unconfirmed' },
    { id: '1', name: 'Confirmed' },
];

const DocumentFilters = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SelectInput source="status" optionValue="name" choices={statuses} alwaysOn />
    </Filter>
);

export const DocumentList = props => (
    <List {...props} title="Documents" filters={<DocumentFilters />} sort={{ field: 'status.confirmed', order: 'ASC' }} bulkActionButtons={false} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField label="First name" source="contact.firstName" sortable={false} />
            <TextField label="Last name" source="contact.lastName" sortable={false} />
            <TextField label="Phone" source="contact.telephone" />
            <TextField label="Address" source="address.streetName" sortable={false} />
            <TextField label="City" source="address.townOrSuburb" sortable={false} />
            <TextField label="Country" source="address.regionOrState" sortable={false} />
            <BooleanField label="Confirmed" source="status.confirmed" />
            <TextField label="Type" source="identificationDocument.type" />
            <TextField label="Date entered" source="identificationDocument.date" />
        </Datagrid>
    </List>
);

const DocumentEditTitle = ({ record }) => {
    return <span>{record ? `Documents: ${record.contact.firstName} ${record.contact.lastName}` : ''}</span>;
};

const DownloadButton = ({ data, onDownload }) => {
    const classes = useStyles();

    return (
        <Button variant="contained" startIcon={<DownloadIcon />} label="Download" className={classes.button} onClick={() => onDownload(data)} />
    );
};

const DocumentImages = props => {
    const classes = useStyles();
    const [images, setImages] = useState([])

    const onDownload = useCallback(data => {
        imageDownloadAction(props.record.id, data.index, data.name)
    }, [props.record.id])

    useEffect(() => {
        const imageList = props.record?.['identificationDocument']?.['images'] || []
        setImages(imageList)
    }, [props.record.identificationDocument])

    return (
        <>
            <div className={classes.smallText}>Document images</div>
            {images.map((i, idx) => (
                <div key={idx} className={classes.downloadRow}>
                    <span className={classes.label}>{i.name}</span>
                    <DownloadButton data={{index: idx, name: i.name}} onDownload={onDownload}/>
                </div>
            ))}
        </>
    )
}

export const DocumentEdit = props => {
    const classes = useInputStyles()

    const transform = data => {
        let documentExpiry = null;
        const validTo = data.identificationDocument.validTo || null;

        if (typeof validTo === 'string') {
            const pad = '00';
            const parts = validTo?.trim().split("-")
            documentExpiry = parts?.length === 3 ? `${(pad + parts[2]).slice(-2)}.${(pad + parts[1]).slice(-2)}.${parts[0]}` : null
        }

        return ({
            ...data,
            identificationDocument: {
                ...data.identificationDocument,
                validTo: documentExpiry
            }
        })
    };

    return (
        <Edit {...props} title={<DocumentEditTitle />} undoable={false} transform={transform}>
            <SimpleForm variant="outlined">
                <TextField disabled source="id" />
                <FunctionField render={record => record ? `${record.contact.firstName} ${record.contact.lastName}` : ''} label="Name" />
                <TextField disabled label="Phone" source="contact.telephone" />
                <TextField disabled label="Email" source="contact.email" />
                <TextField disabled label="Tax number" source="taxNo" />
                <TextInput className={classes.textField} label="Address" source="address.streetName" helperText={false} InputProps={{classes: { input: classes.input }}} />
                <TextInput className={classes.textField} label="City" source="address.townOrSuburb" helperText={false} InputProps={{classes: { input: classes.input }}} />
                <TextField disabled label="Country" source="address.regionOrState" />
                <LabeledDateInput label="Document expiry date" source="identificationDocument.validTo" defaultValue={null} />
                <DocumentImages />
            </SimpleForm>
        </Edit>
    );
};
