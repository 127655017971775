import {CUSTOMER_PRICE_LIST} from "../actions/customerPriceListAction"

const customerPriceList = (previousState = {}, {type, payload}) => {
    if (type === CUSTOMER_PRICE_LIST) {
        const priceListData = {}
        if (Object.keys(payload).length) {
            priceListData['prices'] = payload
        }
        return priceListData
    }
    return previousState
}

export default customerPriceList
