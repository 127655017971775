import React, {useCallback, useEffect, useState} from "react";
import {showNotification, useLogout} from 'react-admin';
import {useDispatch} from "react-redux";
import {classNames, httpClient} from "../helpers/helpers";
import Spinner from "../components/spinner";
import {CheckIcon} from "@heroicons/react/solid";
import * as Config from "../config";

export const UserProfile = () => {
    const dispatch = useDispatch()
    const logout = useLogout()

    const [isSubmitting, setSubmitting] = useState(false)
    const [changesMade, setChangesMade] = useState(false)
    const [userData, setUserData] = useState({})

    const getUserData = useCallback(() => {
        return httpClient(`${Config.ADMIN_API_URL}/admin_users/me`)
            .then(data => {
                switch (data.status) {
                    case 401: throw new Error("Session expired!")
                    default: return data.json
                }
            })
            .catch(error => error)
    }, [])

    const saveUserData = useCallback((userData) => {
        const options = {
            method: 'put',
            mode: 'cors',
            cache: 'no-cache',
            body: JSON.stringify(userData),
        }

        return httpClient(`${Config.ADMIN_API_URL}/admin_users/${userData.Id}`, options)
            .then(data => {
                switch (data.status) {
                    case 401: throw new Error("Session expired!")
                    default: return data.json
                }
            })
            .catch(error => error)
    }, [])

    useEffect(() => {
        getUserData()
            .then(data => {
                if (data instanceof Error) throw data
                setUserData(data)
            })
            .catch(e => {
                if (e.status === 401) {
                    return logout()
                } else {
                    console.log(e.body || e)

                    dispatch(showNotification('Could not load user data', 'warning'));
                }
            })
    }, [getUserData, logout, dispatch]);

    const fieldChanged = (e) => {
        const fieldId = e.target.id
        const newValue = e.target.value

        console.log(`${fieldId} -> ${newValue}`)

        setUserData(prev => {
            return { ...prev, [fieldId]: newValue }
        })

        setChangesMade(true)
    }

    const save = async (e) => {
        if (isSubmitting) return

        const passwordChanges = userData.password || userData.repeatPassword
        const passwordsMatch = userData.password === userData.repeatPassword

        if (passwordChanges && !passwordsMatch) {
            dispatch(showNotification('Passwords do not match', 'error'));
            return
        }

        setSubmitting(true)

        await saveUserData(userData)
            .then(data => {
                if (data instanceof Error) throw data
                setChangesMade(false)
            })
            .catch(e => {
                if (e.status === 401) {
                    return logout()
                } else {
                    console.log(e.body || e)

                    dispatch(showNotification('Could not save user data', 'warning'));
                }
            })
            .finally(async () => {
                setSubmitting(false)
            })
    }

    return (
        <div className="my-8 space-y-6">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">User profile</h1>
                    </div>
                </div>
            </div>

            <section className="grid grid-cols-1 gap-8 sm:grid-cols-2">
                <div className="overflow-hidden bg-white shadow sm:rounded-lg h-fit">
                    <div className="space-y-8 divide-y divide-gray-200 px-4 py-5 sm:p-6 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                            <EntryField data={userData} fieldId="Id" label="ID" isEditable={false} showDivider={false}/>
                            <EntryField data={userData} fieldId="email" label="Email" isEditable={false}/>
                            <EntryField data={userData} fieldId="name" label="Name" placeholder="Full name" onChange={fieldChanged}/>
                            <EntryField data={userData} fieldId="phoneNumber" label="Phone" placeholder="Phone number" onChange={fieldChanged}/>

                            <EntryField data={userData} fieldId="password" label="New password" placeholder="Enter password" secure={true} onChange={fieldChanged}/>
                            <EntryField data={userData} fieldId="repeatPassword" label="Repeat password" placeholder="Confirm password" secure={true} showDivider={false} onChange={fieldChanged}/>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                            type="submit"
                            disabled={!changesMade || isSubmitting}
                            className={classNames(
                                "ml-3 inline-flex justify-center py-2 px-4 border text-sm font-medium rounded-md focus:outline-none",
                                changesMade ? "text-white bg-gray-600 hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 border-transparent shadow-sm" : "text-gray-300 bg-gray-50 border-gray-200 cursor-default"
                            )}
                            onClick={save}
                        >
                            {isSubmitting ? "Saving..." : "Save"}
                            {isSubmitting && <Spinner button/>}
                        </button>
                    </div>
                </div>

                <div className="overflow-hidden bg-white shadow sm:rounded-lg h-fit">
                    <div className="space-y-8 px-4 py-5 sm:p-6 sm:space-y-5">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Multi factor</h3>
                        <MultiFactorItem data={userData} fieldId="multiFactor" value="phone" label="SMS" showDivider={false} />
                        <MultiFactorItem data={userData} fieldId="multiFactor" value="totp" label="TOTP" />
                    </div>
                </div>
            </section>
        </div>
    )
}

const EntryField = ({data = {}, fieldId = 'fieldId', label = '', placeholder = '', isEditable = true, secure = false, showDivider = true, onChange = null}) => {
    return (
        <div className={classNames("sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start", showDivider ? "sm:border-t sm:border-gray-200 sm:pt-5" : "")}>
            <label htmlFor={fieldId} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                    type={secure ? "password" : "text"}
                    name={fieldId}
                    id={fieldId}
                    placeholder={placeholder}
                    disabled={!isEditable}
                    value={data[fieldId] || ''}
                    className={classNames(
                        "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                        !isEditable ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                    )}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

const MultiFactorItem = ({data = {}, fieldId = 'fieldId', value = '', label = '', showDivider = true}) => {
    return (
        <div
            className={classNames("sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start", showDivider ? "sm:border-t sm:border-gray-200 sm:pt-5" : "")}>
            <label htmlFor={fieldId} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {label}
            </label>
            {data[fieldId]?.includes(value) && <CheckIcon className="h-5 w-5 text-green-600"/>}
        </div>
    )
}
