import React, {Fragment, useEffect, useRef, useState} from 'react';
import {classNames, sharedAccessTokenKey, sharedFetch} from "../helpers/helpers";
import * as Config from '../config'
import {DotsVerticalIcon, DownloadIcon, ExclamationIcon, TrashIcon, ViewListIcon} from '@heroicons/react/solid'
import {Menu, Transition} from '@headlessui/react'
import {useLogout} from "react-admin";
import {validReferenceNumber} from "../utils";


export default function SharedUser({match}) {
    const now = new Date(),
        today = now.toLocaleDateString('en-CA');

    const [mep, setMep] = useState(0); // maxExtensionPeriod
    const [id, setId] = useState(); // entry editing
    const [isLoading, setIsLoading] = useState(false); // entry editing
    const [companies, setCompanies] = useState([])
    const [selectedResignee, setSelectedResignee] = useState()
    const [selectedDebtor, setSelectedDebtor] = useState()
    const [originalResignee, setOriginalResignee] = useState()
    const [originalDebtor, setOriginalDebtor] = useState()
    const [updatingResignee, setUpdatingResignee] = useState(false)
    const [updatingDebtor, setUpdatingDebtor] = useState(false)
    const [signedByResignee, setSignedByResignee] = useState(false)
    const [signedByDebtor, setSignedByDebtor] = useState(false)
    const [type, setType] = useState("-")
    const [documentNo, setDocumentNo] = useState("2022-100001")
    const [date, setDate] = useState(today)
    const [transactionDate, setTransactionDate] = useState(today)
    const [resigneeBankAccountList, setResigneeBankAccountList] = useState([])
    const [resigneeBankAccount, setResigneeBankAccount] = useState(null)
    const [factoringPricing, setFactoringPricing] = useState(null)
    const [isProbablyOverdue, setIsProbablyOverdue] = useState(false)
    const logout = useLogout();
    const [invoiceRows, setInvoiceRows] = useState([])
    const [status, setStatus] = useState("")
    const [canEdit, setCanEdit] = useState(true)
    const [onMaxClick, setOnMaxClick] = useState(0)
    const importRef = useRef()
    const uploadDebtorRef = useRef()
    const uploadResigneeRef = useRef()

    useEffect(() => {
        //fetchCompanies()
    }, [])

    function fetchData(id) {
        if (id) {
            setIsLoading(true)
            try {
                sharedFetch(`promissory-notes/` + id, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
                    },
                })
                    .then((resData) => {
                        const {invoices, signedByDebtor, signedByResignee, isProbablyOverdue} = resData


                        setStatus(resData.status)
                        if (!resData.status) {
                            setCanEdit(true)
                        } else {
                            switch (resData.status) {
                                case "UNSIGNED":
                                case "SIGNED_BY_SUPPLIER_CREDITOR":
                                case "SIGNED_BY_BUYER_DEBTOR":
                                    //case "FINANCING_IN_PROGRESS":
                                    setCanEdit(true)
                                    break
                                default:
                                    setCanEdit(false)
                            }
                        }


                        setIsLoading(false)
                    });
            } catch (e) {
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        const id = match.params?.id
        setId(id)
        fetchData(id)
    }, [match.params?.id]);


    function goBack() {
        const link = document.createElement('a');
        link.href = "#/shared-users";
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    function prepareSubmitData(showAlerts = true) {
        const object = {
            //status,
            type,
            documentNo,
            date,
            transactionDate,
        };

        /*fd.forEach(function (value, key) {
            createObject(object, key, value)
        });*/

        if (!selectedResignee && !id) {
            showAlerts && alert("Resignee must not be empty")
            return
        }

        if (!selectedDebtor && !id) {
            showAlerts && alert("Debtor must not be empty")
            return
        }

        if (!id || updatingResignee) {
            object.resignee = {
                ...object.resignee,
                id: selectedResignee.id,
                bankNo: resigneeBankAccount?.accountNumber,
                bankName: resigneeBankAccount?.bank,
                fullName: selectedResignee.shortName || selectedResignee.name,
                taxNo: selectedResignee.taxNumber,
                address: selectedResignee.address,
                postCode: selectedResignee.postCode,
                city: selectedResignee.city,
                country: selectedResignee.country,

                emailDocuments: selectedResignee.data?.emailDocuments || null,
                emailInvoices: selectedResignee.data?.emailInvoices || null,
                language: selectedResignee.data?.language || null,
            }
        }

        if (!id || updatingDebtor) {
            object.debtor = {
                id: selectedDebtor.id,
                fullName: selectedDebtor.shortName || selectedDebtor.name,
                taxNo: selectedDebtor.taxNumber,
                address: selectedDebtor.address,
                postCode: selectedDebtor.postCode,
                city: selectedDebtor.city,
                country: selectedDebtor.country,

                emailDocuments: selectedDebtor.data?.emailDocuments || null,
                emailInvoices: selectedDebtor.data?.emailInvoices || null,
                language: selectedDebtor.data?.language || null,
            }
            if (mep > 0) object.debtor.maxExtensionPeriod = mep
        }

        object.items = invoiceRows
        if ((!id && (type === "F" || type === "RF")) || (id && (updatingDebtor || updatingResignee))) object.factoringPricing = factoringPricing
        object.currency = "EUR"

        return object
    }

    function onSubmit(e) {
        e.preventDefault();

        /*if (uploading) {
            alert("Please wait for the upload to complete before saving changes!")
            return
        }*/

        const object = prepareSubmitData()
        if (!object) return

        const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
            },
            body = JSON.stringify(object)

        sharedFetch(`promissory-notes${id ? ("/" + id) : ""}`, id ? {
            method: 'PUT',
            headers, body
        } : {
            method: 'POST',
            headers, body
        }).then(() => {
            goBack()
        })
    }

    return (
        <>
            <div className="my-8 space-y-6">
                <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-4 flex items-center space-x-4">
                        <button
                            type="button"

                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                            <DownloadIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                          aria-hidden="true"/>
                            <span>Download PDF</span>
                        </button>

                        {isProbablyOverdue === true && (
                            <span
                                className="inline-flex items-center pl-1.5 pr-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-700"><ExclamationIcon
                                className="h-5 w-5" aria-hidden="true"/>&nbsp;Overdue</span>
                        )}
                    </div>

                    <div className="ml-4 mt-4 flex-shrink-0 flex">
                        <div className="flex-shrink-0 self-center flex ml-4">
                            <Menu as="div" className="relative z-30 inline-block text-left">
                                <div>
                                    <Menu.Button
                                        className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                        <span className="sr-only">Open options</span>
                                        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true"/>
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {id && (
                                                <>
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <a
                                                                href={"#/logs?documentation=" + id}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'flex px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                <ViewListIcon className="mr-3 h-5 w-5 text-gray-400"
                                                                              aria-hidden="true"/>
                                                                <span>Logs</span>
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <a
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'flex px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                <TrashIcon className="mr-3 h-5 w-5 text-gray-400"
                                                                           aria-hidden="true"/>
                                                                <span>Delete</span>
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                </>
                                            )}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>

                <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                    <form onSubmit={onSubmit} method="post"
                          action={`${Config.SHARED_API_URL}/promissory-notes`}
                          className="space-y-8">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div>
                                <div
                                    className="mt-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                    <label htmlFor="status"
                                           className={classNames(
                                               "block text-sm font-medium sm:mt-px sm:pt-2",
                                               id ? "text-gray-700" : "text-gray-300"
                                           )}>
                                        Status
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <select
                                            id="status"
                                            name="status"
                                            disabled={true}
                                            onChange={e => setStatus(e.target.value)}
                                            value={status}
                                            className={classNames(
                                                "max-w-lg block focus:ring-gray-500 focus:border-gray-500 w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md border-gray-300 bg-gray-50 text-gray-600",
                                            )}
                                        >
                                            <option value="">Unsigned</option>
                                            <option value="UNSIGNED">Unsigned</option>
                                            <option value="SIGNED_BY_SUPPLIER_CREDITOR">Signed by supplier/creditor
                                            </option>
                                            <option value="SIGNED_BY_BUYER_DEBTOR">Signed by buyer/debtor</option>
                                            <option value="FINANCING_IN_PROGRESS">Financing in progress</option>
                                            <option value="FINANCED">Financed</option>
                                            <option value="PAID">Paid</option>
                                            <option value="DELAYED">Delayed</option>
                                            <option value="IN_RECOVERY">In recovery</option>
                                            <option value="INSURANCE_CLAIM">Insurance claim</option>
                                            <option value="REPAID">Repaid</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="sm:mt-5 space-y-6 sm:space-y-5">
                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="type"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Type
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <select
                                                id="type"
                                                name="type"
                                                disabled={!canEdit}
                                                onChange={e => setType(e.target.value)}
                                                value={type}
                                                className={classNames(
                                                    "max-w-lg block focus:ring-gray-500 focus:border-gray-500 w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-300 bg-gray-50 text-gray-600" : "border-gray-300"
                                                )}
                                            >
                                                <option value="-"> -</option>
                                                <option value="F">Factoring</option>
                                                <option value="RF">Reverse factoring</option>
                                                <option value="IF">International factoring</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="documentNo"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Document Number
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                name="documentNo"
                                                id="documentNo"
                                                pattern="[\d-]{1,15}"
                                                required
                                                disabled={!canEdit}
                                                value={documentNo}
                                                onChange={e => setDocumentNo(validReferenceNumber(e.target.value) ? e.target.value : documentNo)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="date"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Date
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="date"
                                                name="date"
                                                id="date"
                                                disabled={!canEdit}
                                                value={date}
                                                onChange={e => setDate(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="transactionDate"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Transaction Date
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="date"
                                                name="transactionDate"
                                                id="transactionDate"
                                                disabled={!canEdit}
                                                value={transactionDate}
                                                onChange={e => setTransactionDate(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>




                                </div>
                            </div>
                        </div>


                        <div className="pt-5">
                            <div className="flex justify-end">
                                <a
                                    href="#/shared-users"
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                >
                                    Cancel
                                </a>
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                >
                                    {id ? "Update" : "Create"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
};
