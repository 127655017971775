import * as Config from '../config'

export const COMPANY_LOOKUP = 'COMPANY_LOOKUP';

export const updateCompanyData = (companyData) => ({
    type: COMPANY_LOOKUP,
    payload: companyData,
});

export const companyLookupAction = (dispatch, taxNumber) => {
    fetch(`${Config.API_URL}/companies?tax_no=${taxNumber}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    }).then(res => {
        return res.json()
    }).then(companyData => {
        dispatch(updateCompanyData(companyData));
    }).catch((err) => {
        console.log(err);

        dispatch(updateCompanyData({}));
    });

    // const state = getState(), userId = state.user.currentUser.Id;
    //
    // fetch(Config.API_URL + '/accountBalance', {
    //     method: 'get',
    //     headers: {
    //         "Accept": "application/json",
    //         "Authorization": authenticationHeader,
    //         "userId": userId
    //     },
    // }).then(authMiddleware(dispatch)).then(res => {
    //     return res.json()
    // }).then(accountBalance => {
    //     dispatch({
    //         type: COMPANY_LOOKUP,
    //         accountBalance
    //     })
    // }).catch(err => {
    //     console.log(err);
    //
    //     dispatch({
    //         type: COMPANY_LOOKUP,
    //         accountBalance: {}
    //     })
    // })
};