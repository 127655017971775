import React, {useEffect, useState} from 'react';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid'
import {FINANCE_APP_URL} from "../config";
import {classNames, sharedAccessTokenKey, sharedFetch} from "../helpers/helpers";
import {useLogout} from "react-admin";

import parsePhoneNumber from "libphonenumber-js";
import Spinner from "../components/spinner";

function formatAddress(addressObject) {
    if (!addressObject) return null
    let address = ""
    if (addressObject.address) address += addressObject.address
    else if (addressObject.address1) address += addressObject.address1
    else if (addressObject.streetName) {
        address += addressObject.streetName + " "
        if (addressObject.streetNumber) address += addressObject.streetNumber
    }
    if (addressObject.postCode) address += (address.length > 0 ? ", " : "") + addressObject.postCode + " "
    if (address && addressObject.city) address += addressObject.city
    if (address && addressObject.country?.name) address += ", " + addressObject.country.name
    return address
}

const defaultColumns = [
        /*{v: d => d.id, label: "ID"},*/
        {v: d => d.profile?.fullName, label: "Full Name", sort: "fullName"},
        {
            v: d => formatAddress(d.profile?.address),
            label: "Address",
            href: d => "https://maps.google.com/?q=" + encodeURIComponent(formatAddress(d.profile?.address))
        },
        {
            v: d => d.profile?.email || d.email,
            label: "Email",
            sort: "email",
            href: d => "mailto:" + (d.profile?.email || d.email || "")
        },
        {v: d => d.emailVerified === true ? "Yes" : "-", label: "Email verified"},
        {v: d => d.hasCredentials === true ? "Yes" : "-", label: "Credentials"},
        {
            v: d => d.profile?.phone ? parsePhoneNumber(d.profile?.phone).formatInternational() : "",
            label: "Phone",
            href: d => "tel:" + (d.profile?.phone || "")
        },
        {v: d => d.profile?.appAccess?.join(", "), label: "Apps", sort: "appAccess", className: "capitalize"},
        {v: d => d.enrolledFactors && d.enrolledFactors.length > 0 ? "Yes" : "-", label: "2FA"},
        {v: d => d.lastSignedInAt, label: "Last signed in"},
        {
            v: d => "Sign in",
            label: "Sign in",
            request: true,
        },
    ],
    defaultOrderBy = "createdAt",
    defaultOrderByDirection = "desc"

const limit = 10;

export const SharedUsersList = () => {
    const [columns, setColumns] = useState(defaultColumns)
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [orderByDirection, setOrderByDirection] = useState(defaultOrderByDirection)
    const [result, setResult] = useState({})
    const [offset, setOffset] = useState(0)
    const [fetchingLink, setFetchingLink] = useState(false)
    const logout = useLogout();

    const [isFilterOn, setIsFilterOn] = useState(false)
    const [filterCount, setFilterCount] = useState(0)

    useEffect(() => {
        fetchList()
    }, [orderBy, orderByDirection, offset, isFilterOn])

    useEffect(() => {
        const listener = function (event) {
            console.log('New window event:', event)
            // Check the origin of the message to ensure it's from the expected source
            if (event.origin !== FINANCE_APP_URL) {
                return;
            }

            // Process the received data
            console.log('Data received from the new window:', event.data);
        }
        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener);
        }
    }, [])

    function fetchList(next = "") {
        sharedFetch(`admin/users?limit=${limit}&nextPageToken=${next}&orderBy=${orderBy}&orderByDirection=${orderByDirection}&offset=${offset}${isFilterOn ? "&filter=isProbablyOverdue" : ""}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        })
            .then((json) => {
                setResult(json)
                setFilterCount(json?.totalOverdue || 0)
            })
    }

    function nextPage(e) {
        //result?.offset + result?.count < result?.total
        if (result?.offset + result?.count < result?.total) setOffset(offset + limit)
    }

    function prevPage(e) {
        if (offset > 0) setOffset(offset - limit)
    }

    function sort(columnIndex) {
        return function onClick(e) {
            e.preventDefault()
            const col = columns[columnIndex]
            if (col?.sort) {
                switch (col.direction) {
                    case "asc":
                        col.direction = "desc"
                        break;
                    case "desc":
                        col.direction = null
                        break;
                    default:
                        col.direction = "asc"
                }
                if (col.direction) {
                    setOrderBy(col.sort)
                    setOrderByDirection(col.direction)
                } else {
                    setOrderBy(defaultOrderBy)
                    setOrderByDirection(defaultOrderByDirection)
                }
                setColumns(columns.map((it, i) => i === columnIndex ? {...col} : {...it, direction: null}))
            }
        }
    }

    function toggleFilter(e) {
        e.preventDefault();
        const isFilterOnNewState = !isFilterOn
        setIsFilterOn(isFilterOnNewState)
    }

    function requestSignInAs(d) {
        setFetchingLink(d.uid)
        sharedFetch(`admin/auth/customtoken?uid=${d.uid}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        })
            .then(res => res.text())
            .then((token) => {
                if (token) window.open(FINANCE_APP_URL + '/signinas?token=' + token, '_blank', 'noopener,noreferrer');
                else alert('Failed to generate sign in link')
            })
            .finally(() => {
                setFetchingLink(false)
            })
    }

    return (
        <>
            <div className="my-8 space-y-6">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="inline-flex text-xl font-semibold text-gray-900">Finance Users</h1>
                            {/*<p className="mt-2 text-sm text-gray-700">
                                A list of all the users in your account including their name, title, email and role.
                            </p>*/}
                            <div className="sm:px-8 inline-flex">
                                {/*<button
                                    type="button"
                                    onClick={toggleFilter}
                                    className={classNames(
                                        "inline-flex rounded-full items-center py-1 text-sm font-medium box-border border-2",
                                        isFilterOn ? "pl-1 pr-1 border-current" : "pr-2.5 pl-1 border-transparent",
                                        filterCount > 0 ? "bg-red-100 text-red-700" : "bg-gray-100 text-gray-700"
                                    )}>
                                    <div
                                        className={classNames(
                                            "flex-shrink-0 mr-1 h-5 rounded-full inline-flex items-center justify-center",
                                            filterCount > 0 ? "text-red-400 bg-red-50" : "text-gray-400 bg-gray-50"
                                        )}
                                    >
                                        <span className="sr-only">Filter count</span>
                                        <span className="px-1">{filterCount}</span>
                                    </div>
                                    Filtered
                                </button>*/}
                            </div>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            {/*<a
                                type="button"
                                href="#/shared-users/create"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Create new
                            </a>*/}
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            {columns.map((col, i) => (
                                                <th key={col.label} scope="col"
                                                    className={classNames(
                                                        "text-left text-sm font-semibold text-gray-900",
                                                        i === 0 ? "py-3.5 pl-4 pr-3 sm:pl-6" : "px-3 py-3.5"
                                                    )}>
                                                    <span onClick={sort(i)} className={classNames(
                                                        "group inline-flex truncate",
                                                        col.sort ? "cursor-pointer" : "cursor-default"
                                                    )}>
                                                        {col.label}
                                                        <span className={classNames(
                                                            col.direction ? "ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>{col.direction === "asc" ? (
                                                            <ChevronUpIcon className="h-5 w-5" aria-hidden="true"/>
                                                        ) : col.direction === "desc" ? (
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
                                                        ) : null}</span>
                                                    </span>
                                                </th>
                                            ))}
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {result?.results?.map((data, index) => (
                                            <tr key={index}>
                                                {columns.map((col, i) => (
                                                    <td key={col.label}
                                                        className={classNames(
                                                            i === 0 ? "py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6" : "px-3 py-4 text-gray-500"
                                                        )}>
                                                        {col.request ? (
                                                            <>
                                                                {fetchingLink === data.uid ? (
                                                                    <Spinner button/>
                                                                ) : (
                                                                    <span
                                                                        onClick={() => requestSignInAs(data)}
                                                                        className={classNames(
                                                                            col.className || "",
                                                                            "p-0 whitespace-nowrap text-sm truncate hover:text-gray-900 underline cursor-pointer",
                                                                        )}>
                                                                {col.v(data)}
                                                            </span>
                                                                )}
                                                            </>
                                                        ) : col.href ? (
                                                            <a
                                                                className={classNames(
                                                                    col.className || "",
                                                                    "p-0 whitespace-nowrap text-sm truncate hover:text-gray-900 underline",
                                                                )}
                                                                rel="noreferrer"
                                                                target="_blank"
                                                                href={col.href(data)}>
                                                                {col.v(data)}
                                                            </a>
                                                        ) : (
                                                            <span
                                                                className={classNames(
                                                                    col.className || "",
                                                                    "p-0 whitespace-nowrap text-sm truncate",
                                                                )}>
                                                                {col.v(data)}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    {/*<a
                                                        href={"#/shared-users/" + data.uid}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <PencilIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </a>*/}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                    <nav
                                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                        aria-label="Pagination"
                                    >
                                        <div className="hidden sm:block">
                                            <p className="text-sm text-gray-700">
                                                Showing <span
                                                className="font-medium">{(result?.offset + 1) || "0"}</span> to <span
                                                className="font-medium">{result?.offset + result?.count || "0"}</span> of{' '}
                                                <span className="font-medium">{result?.total || "0"}</span> results
                                            </p>
                                        </div>
                                        <div className="flex-1 flex justify-between sm:justify-end">
                                            <button
                                                type="button"
                                                onClick={prevPage}
                                                className={classNames(
                                                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    result?.offset ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Previous
                                            </button>
                                            <button
                                                type="button"
                                                onClick={nextPage}
                                                className={classNames(
                                                    "ml-3 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    (result?.offset + result?.count < result?.total) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
