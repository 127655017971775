import React, {useEffect, useState} from 'react';
import {classNames, sharedAccessTokenKey, sharedFetch,} from "../helpers/helpers";
import {BookOpenIcon} from "@heroicons/react/solid";
import {useLogout} from "react-admin";
import Spinner from "../components/spinner";
import {FINANCING_SOURCE, QUEUE_PROTECTION, QUEUE_STATUS} from "../promissory-notes/consts";
import {isNumeric} from "../utils";

export default function QueueEntry({match}) {
    const {id} = match.params ? match.params : {};
    const [isLoading, setIsLoading] = useState(false); // entry editing

    const [serverData, setServerData] = useState({})
    const [investmentInterestRate, setInvestmentInterestRate] = useState("")
    const [financingSource, setFinancingSource] = useState("-")
    const [protectionType, setProtectionType] = useState("-")
    const [status, setStatus] = useState("")
    const logout = useLogout();

    useEffect(() => {
        id && fetch()
    }, [id]);

    function fetch() {
        setIsLoading(true)
        sharedFetch(`admin/finance/queue/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        }).then(data => {
            setServerData(data)
            setInvestmentInterestRate(data?.investmentInterestRate || "")
            setFinancingSource(data?.financingSource || "")
            setProtectionType(data?.protectionType || "")
            setStatus(data?.status || "")
        }).finally(() => setIsLoading(false))
    }

    function onSubmit(e) {
        e.preventDefault();
        const updates = {}

        if (serverData?.investmentInterestRate !== investmentInterestRate) updates.investmentInterestRate = isNumeric(investmentInterestRate) ? Number(investmentInterestRate) : ""
        if (serverData?.financingSource !== financingSource) updates.financingSource = financingSource
        if (serverData?.protectionType !== protectionType) updates.protectionType = protectionType
        if (serverData?.status !== status) updates.status = status

        sharedFetch(`admin/finance/queue/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            },
            body: JSON.stringify(updates)
        }).then(() => {
            fetch()
        }).catch(e => {
            if (e.status === 401) {
                logout();
            }
        })
    }


    return (
        <>
            <div className="my-8 space-y-6">
                {isLoading ? (
                    <Spinner/>
                ) : (
                    <form onSubmit={onSubmit} className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                        <div className="space-y-8">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                <div>
                                    <div>
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Queue Entry</h3>
                                        {id && (
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                Editing queue entry with ID: {id}
                                            </p>
                                        )}
                                    </div>
                                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="type"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Promissory Note
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <div
                                                    className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                                >
                                                    <div className="flex-shrink-0">
                                                        <BookOpenIcon className="h-10 w-10 rounded-full"/>
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        <a href={"#/promissory-notes/" + serverData?.promissoryNoteRef?._path?.segments[3]}
                                                           className="focus:outline-none">
                                                            <span className="absolute inset-0" aria-hidden="true"/>
                                                            <p className="text-sm font-medium text-gray-900">Note
                                                                Entry</p>
                                                            <p className="text-sm text-gray-500 truncate">Click to
                                                                open</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {serverData?.addInvestmentResponse?.id && (
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                                <label htmlFor="type"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Investment
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    <div
                                                        className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                                    >
                                                        <div className="flex-shrink-0">
                                                            <BookOpenIcon className="h-10 w-10 rounded-full"/>
                                                        </div>
                                                        <div className="flex-1 min-w-0">
                                                            <a href={"#/investments/" + serverData.addInvestmentResponse.id}
                                                               className="focus:outline-none">
                                                                <span className="absolute inset-0" aria-hidden="true"/>
                                                                <p className="text-sm font-medium text-gray-900">Investment
                                                                    Entry</p>
                                                                <p className="text-sm text-gray-500 truncate">Click to
                                                                    open</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                            <label htmlFor="status"
                                                   className={classNames(
                                                       "block text-sm font-medium sm:mt-px sm:pt-2 text-gray-700",
                                                       /*!id ? "text-gray-300" : */"text-gray-700"
                                                   )}>
                                                Status
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <select
                                                    disabled
                                                    id="status"
                                                    name="status"
                                                    /*onChange={e => setStatus(e.target.value)}*/
                                                    value={status}
                                                    className={classNames(
                                                        "max-w-lg block focus:ring-gray-500 focus:border-gray-500 w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                                                        "border-gray-300 bg-gray-50 text-gray-600"
                                                        /*!id ? "border-gray-300 bg-gray-50 text-gray-600" : */ /*"border-gray-300"*/
                                                    )}
                                                >
                                                    <option value="" disabled>Status</option>
                                                    {Object.keys(QUEUE_STATUS).map(key => (
                                                        <option key={key}
                                                                value={key}>{QUEUE_STATUS[key]}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                            <label htmlFor="financingSource"
                                                   className={classNames(
                                                       "block text-sm font-medium sm:mt-px sm:pt-2 text-gray-700",
                                                       /*!id ? "text-gray-300" : */"text-gray-700"
                                                   )}>
                                                Financing Source
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <select
                                                    disabled={!!serverData?.addInvestmentResponse?.id}
                                                    id="financingSource"
                                                    name="financingSource"
                                                    onChange={e => setFinancingSource(e.target.value)}
                                                    value={financingSource}
                                                    className={classNames(
                                                        "max-w-lg block focus:ring-gray-500 focus:border-gray-500 w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                                                        !!serverData?.addInvestmentResponse?.id ? "border-gray-300 bg-gray-50 text-gray-600" : "border-gray-300"
                                                    )}
                                                >
                                                    <option disabled>Financing Source</option>
                                                    <option value="-"></option>
                                                    {Object.keys(FINANCING_SOURCE).map(key => (
                                                        <option key={key}
                                                                value={FINANCING_SOURCE[key]}>{key}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                            <label htmlFor="protection"
                                                   className={classNames(
                                                       "block text-sm font-medium sm:mt-px sm:pt-2 text-gray-700",
                                                       /*!id ? "text-gray-300" : */"text-gray-700"
                                                   )}>
                                                Protection
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <select
                                                    disabled={!!serverData?.addInvestmentResponse?.id}
                                                    id="protection"
                                                    name="protection"
                                                    onChange={e => setProtectionType(e.target.value)}
                                                    value={protectionType}
                                                    className={classNames(
                                                        "max-w-lg block focus:ring-gray-500 focus:border-gray-500 w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                                                        !!serverData?.addInvestmentResponse?.id ? "border-gray-300 bg-gray-50 text-gray-600" : "border-gray-300"
                                                    )}
                                                >
                                                    <option disabled>Protection</option>
                                                    <option value="-"></option>
                                                    {Object.keys(QUEUE_PROTECTION).map(key => (
                                                        <option key={key}
                                                                value={QUEUE_PROTECTION[key]}>{QUEUE_PROTECTION[key]}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                            <label htmlFor="investmentInterestRate"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Investment Interest Rate
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <input
                                                    disabled={!!serverData?.addInvestmentResponse?.id}
                                                    type="number"
                                                    max={1}
                                                    step={0.0001}
                                                    placeholder={"0.0001"}
                                                    name="investmentInterestRate"
                                                    id="investmentInterestRate"
                                                    value={investmentInterestRate}
                                                    onChange={e => setInvestmentInterestRate(e.target.value)}
                                                    className={classNames(
                                                        "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                        !!serverData?.addInvestmentResponse?.id ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                    )}
                                                />
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className="pt-5">
                                <div className="flex justify-end">
                                    <a
                                        href="#/queue"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    >
                                        Go back
                                    </a>
                                    <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </>
    )
};
