import React, {useEffect} from "react";
import {useField} from "react-final-form";
import {TextField} from "@material-ui/core";
import {httpClient} from "../helpers/helpers";
import * as Config from "../config";
import useInputStyles from "../components/InputStyles";

const InvestmentNumberInput = props => {
    const classes = useInputStyles()
    const {input: {value, onChange}, meta} = useField(props.source)

    useEffect(() => {
        (async () => {
            try {
                let response = await httpClient(`${Config.ADMIN_API_URL}/investments/nextNumber`);
                let data = response.body;
                onChange(data)
            } catch (e) {
                console.log(e.body || e);
            }
        }) ();
    }, []);

    return (
        <TextField
            variant={props.variant}
            className={classes.textField}
            size="small"
            label={props.label}
            helperText={meta.error || false}
            error={!!meta.error}
            value={value}
            InputProps={{ classes: { input: classes.input }}}
            onChange={onChange}/>
    )
}

export default InvestmentNumberInput
