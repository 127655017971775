import React, {Fragment, useEffect, useRef, useState} from 'react';
import {classNames, httpClient, sharedAccessTokenKey, sharedFetch,} from "../helpers/helpers";
import {ADMIN_API_URL} from '../config'
import {useLogout} from "react-admin";
import {fileToBlob, validReferenceNumber} from "../utils";
import {
    CheckIcon,
    InformationCircleIcon,
    PlusSmIcon as PlusSmIconSolid,
    SelectorIcon,
    XIcon
} from "@heroicons/react/solid";
import {Combobox, Dialog, Transition} from "@headlessui/react";
import AmountInputTailwind from "../components/AmountInputTailwind";
import {XMLParser} from "fast-xml-parser"


function DataTable({rows, updateRow, deleteRow, canEdit, type, companies, selectedClientId}) {
    const limit = 20;
    const [pagination, setPagination] = useState(false)
    const [page, setPage] = useState(0)

    useEffect(() => {
        setPagination(rows?.length > limit)
    }, [rows])

    return (
        <div className="bg-white divide-y divide-gray-200">
            <div className="divide-y divide-gray-200">
                {rows.map((row, index) => (
                    <div key={row._id} className={classNames(
                        (index >= page * limit) && (index < (page + 1) * limit) ? "" : "hidden"
                    )}>
                        <Row row={row} updateRow={updateRow} deleteRow={deleteRow} selectedClientId={selectedClientId}
                             canEdit={canEdit} type={type} companies={companies}/>
                    </div>
                ))}
            </div>
            {rows.length === 0 && (
                <div
                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-400 text-center">
                    <span>No entries</span>
                </div>
            )}

            <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
                aria-label="Pagination"
            >
                <div className="hidden py-2 sm:block">
                    <p className="text-sm text-gray-700">
                        Showing <span
                        className="font-medium">{rows.length > 0 ? (page * limit) + 1 : "0"}</span> to <span
                        className="font-medium">{rows.length > 0 ? ((page + 1) * limit) > rows.length ? rows.length : (page + 1) * limit : "0"}</span>
                        {' '}of{' '}
                        <span className="font-medium">{rows.length || "0"}</span> results
                        total
                    </p>
                </div>
                <div className="flex-1 flex justify-between sm:justify-end">
                    <button
                        type="button"
                        disabled={!(pagination && page > 0)}
                        onClick={() => setPage(page - 1)}
                        className={classNames(
                            "relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                            (pagination && page > 0) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                        )}
                    >
                        Previous
                    </button>
                    <button
                        type="button"
                        disabled={!(pagination && ((page + 1) * limit) - 1 < rows.length)}
                        onClick={() => setPage(page + 1)}
                        className={classNames(
                            "ml-3 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                            (pagination && ((page + 1) * limit) - 1 < rows.length) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                        )}
                    >
                        Next
                    </button>
                </div>
            </nav>
        </div>
    )
}

function Row({row, updateRow, deleteRow, canEdit, selectedClientId, type, companies}) {
    const [debtor, setDebtor] = useState(row.debtor || "")
    const [selectedDebtor, setSelectedDebtor] = useState()
    const [amount, setAmount] = useState(row.amount || "")
    const [financingDuration, setFinancingDuration] = useState(row.financingDuration || "")
    const [factoringPricing, setFactoringPricing] = useState(null)

    useEffect(() => {
        updateRow && updateRow({_id: row._id, amount, financingDuration, debtor: selectedDebtor})
    }, [amount, financingDuration, selectedDebtor])

    /*useEffect(() => {
        async function run() {
            if (type && selectedDebtor?.id) {
                setFactoringPricing(await fetchFactoringPricing(type, selectedDebtor?.id, selectedClientId))
            } else {
                setFactoringPricing(null)
            }
        }

        run()
    }, [selectedDebtor])*/


    const [query, setQuery] = useState('')
    const filteredCompanies =
        query === ''
            ? companies
            : companies.filter((item) =>
                ((item.shortName || item.name) + " " + item.taxNumber)
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
        <div
            className={classNames(
                "grid",
                type === "RF" ?
                    "grid-cols-[minmax(0px,_1fr)_minmax(0px,_1fr)_50px]" :
                    "grid-cols-[minmax(0px,_1fr)_minmax(0px,_1fr)_50px]"
            )}>
            {type === "F" && (
                <div className="flex-1 p-2 w-full">
                    <Combobox value={selectedDebtor} onChange={setSelectedDebtor}>
                        <div className="relative">
                            <div
                                className="relative overflow-hidden cursor-default rounded-md">
                                <Combobox.Input
                                    autoComplete="off"
                                    className={classNames(
                                        "flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                                        !canEdit ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                                    )}
                                    displayValue={(item) => (item?.shortName || item?.name || "")}
                                    onChange={(event) => setQuery(event.target.value)}
                                />
                                <Combobox.Button
                                    className="absolute inset-y-0 right-0 flex items-center pr-2">
                                    <SelectorIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </Combobox.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={() => setQuery('')}
                            >
                                <Combobox.Options
                                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                    {filteredCompanies.length === 0 && query !== '' ? (
                                        <div
                                            className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                            Nothing found.
                                        </div>
                                    ) : (
                                        filteredCompanies.map((item) => (
                                            <Combobox.Option
                                                key={item.taxNumber}
                                                className={({active}) =>
                                                    classNames(
                                                        active ? 'text-white bg-gray-600' : 'text-gray-900',
                                                        'cursor-default select-none relative py-2 pl-3 pr-9 pl-8'
                                                    )
                                                }
                                                value={item}
                                            >
                                                {({selected, active}) => (
                                                    <>
                                                        <div className="flex">
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                            {item.shortName || item.name}
                          </span>
                                                            <span
                                                                className={classNames(active ? 'text-gray-200' : 'text-gray-500', 'ml-2 truncate')}>
                            {item.taxNumber}
                          </span>
                                                        </div>

                                                        {selected ? (
                                                            <span
                                                                className={classNames(
                                                                    active ? 'text-white' : 'text-gray-600',
                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                )}
                                                            >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Combobox.Option>
                                        ))
                                    )}
                                </Combobox.Options>
                            </Transition>
                        </div>
                    </Combobox>
                </div>
            )}
            <div className="flex-1 p-2 w-full">
                <AmountInputTailwind
                    onChange={e => setAmount(e.target.value)}
                    disabled={!canEdit}
                    value={amount}
                    className={classNames(
                        "flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                        !canEdit ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                    )}
                />
            </div>
            {/*<div className="flex-1 p-2 w-full">
                <input
                    className={classNames(
                        "flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                        !canEdit ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                    )}
                    type="text"
                    disabled={!canEdit}
                    onChange={e => setFinancingDuration(e.target.value)} value={financingDuration}
                />
            </div>*/}
            <div className="flex items-center justify-end px-4">
                {canEdit && (
                    <button
                        type="button"
                        disabled={!canEdit}
                        onClick={() => deleteRow(row._id)}
                        className={classNames(
                            "ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                            canEdit ? "text-gray-500 bg-gray-200 hover:bg-gray-300" : "text-gray-300 bg-gray-50 cursor-default"
                        )}
                    >
                        <XIcon className="h-3 w-3" aria-hidden="true"/>
                    </button>
                )}
            </div>
        </div>
    )
}

export default function DebtorPaymentsImport() {
    const now = new Date(),
        today = now.toLocaleDateString('en-CA');

    const [id, setId] = useState(); // entry editing
    const [isLoading, setIsLoading] = useState(false); // entry editing

    const [canEdit, setCanEdit] = useState(true)
    const [type, setType] = useState("")
    const [factoringPricing, setFactoringPricing] = useState(null)
    const [importedFile, setImportedFile] = useState("")
    const [date, setDate] = useState(today)
    const [companies, setCompanies] = useState([])
    const [selectedClient, setSelectedClient] = useState()
    const [amount, setAmount] = useState("")
    const [financingDays, setFinancingDays] = useState("")
    const [infoCosts, setInfoCosts] = useState([])

    const [rows, setRows] = useState([])
    const logout = useLogout();

    const [open, setOpen] = useState(false)

    const cancelButtonRef = useRef(null)

    function prepareSubmitData(showAlerts = true) {
        const object = {
            type,
            //number: documentNo,
            date,
        };

        if (selectedClient) {
            object.client = {
                oid: "" + selectedClient.id,
                name: selectedClient.name || selectedClient.shortName,
                taxNo: selectedClient.taxNumber,
                address: {
                    address1: selectedClient.address,
                    postCode: selectedClient.postCode,
                    city: selectedClient.city,
                    country: selectedClient.country,
                }
            }
        }

        if (type === "F" && rows.length > 0) {
            object.factoringDebtorList = []
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i]
                if (!row?.amount || !row?.debtor) continue
                object.factoringDebtorList.push({
                    amount: Number((row.amount || "").replace(".", "").replace(",", ".")),
                    oid: "" + row.debtor.id,
                    taxNo: "" + row.debtor.taxNumber,
                    financingDuration: Number(row.financingDuration),
                })
            }
        } else if (type === "RF") {
            object.amount = Number((amount || "").replace(".", "").replace(",", "."))
            object.daysOfExtension = Number(financingDays)
        }

        return object
    }

    function onSubmit(e) {
        e.preventDefault();

        const object = prepareSubmitData(),
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
            },
            body = JSON.stringify(object)

        sharedFetch(`finance/quotes${id ? ("/" + id) : ""}`, id ? {
            method: 'PUT',
            headers, body
        } : {
            method: 'POST',
            headers, body
        })
            .then(res => {
                if (res) {
                    goBack()
                }
            })

    }

    function goBack() {
        const link = document.createElement('a');
        link.href = "#/quotes";
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    function addRow() {
        setRows([...rows, {
            _id: "" + Date.now(),
            debtor: "",
            amount: "",
            financingDuration: "",
        }])
    }

    function deleteRow(_id) {
        setRows(rows.filter(row => row._id !== _id))
    }

    function updateRow(data) {
        setRows(rows.map(row => row._id === data._id ? data : row))
    }

    function renderTable(rows, canEdit) {
        return (
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <div className="min-w-full divide-y divide-gray-200">
                                <div className="bg-gray-50">
                                    <div
                                        className={classNames(
                                            "grid",
                                            type === "RF" ?
                                                "grid-cols-[minmax(0px,_1fr)_minmax(0px,_1fr)_50px]" :
                                                "grid-cols-[minmax(0px,_1fr)_minmax(0px,_1fr)_50px]"
                                        )}>
                                        {type === "F" && (
                                            <>
                                                <div
                                                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Debtor
                                                </div>
                                            </>
                                        )}
                                        <div
                                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Amount
                                        </div>
                                        {/*<div
                                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            No. of Financing Days
                                        </div>*/}
                                    </div>
                                </div>
                                <DataTable rows={rows} updateRow={updateRow} deleteRow={deleteRow} canEdit={canEdit}
                                           type={type} companies={companies} selectedClientId={selectedClient?.id}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        async function importXML() {
            console.log("importedFile", importedFile)
            if (importedFile) {
                const parser = new XMLParser()
                const buff = await importedFile.arrayBuffer()
                const blob = new Blob([new Uint8Array(buff)], {type: importedFile.type})
                console.log("buff", buff)
                console.log("blob", blob)
                const parsed = parser.parse(await blob.text())
                console.log("parsed", parsed)

                // let's console log all debtors
                try {
                    const entries = parsed.Document.BkToCstmrStmt.Stmt.Ntry
                    for (let i = 0; i < entries.length; i++) {
                        const entry = entries[i]
                        const debtorName = entry.NtryDtls.TxDtls.RltdPties.Dbtr.Nm
                        //console.log("debtorName", debtorName)
                        if (debtorName.startsWith("MIMOVRSTE")) {
                            console.log("entry", entry)
                        }
                    }
                } catch (e) {

                }
            }
        }

        importXML()
    }, [importedFile])

    return (
        <>
            <div className="my-8 space-y-6">
                <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                    <form onSubmit={onSubmit} className="space-y-8">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div>
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Import</h3>
                                </div>
                                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="file"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            File
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="file"
                                                name="file"
                                                id="file"
                                                onChange={e => setImportedFile(e.target?.files?.item(0))}
                                                placeholder="Select file"
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    /*true ? "border-gray-200 bg-gray-50 text-gray-500" : */"border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="pt-5">
                            <div className="flex justify-end">
                                <a
                                    href="#/debtor-payments"
                                    className="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                >
                                    Cancel
                                </a>
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                >
                                    Import
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div
                                                className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <InformationCircleIcon className="h-6 w-6 text-blue-600"
                                                                       aria-hidden="true"/>
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left sm:flex-grow">
                                                <Dialog.Title as="h3"
                                                              className="text-lg font-medium leading-6 text-gray-900">
                                                    Cost Calculation
                                                </Dialog.Title>

                                                {infoCosts && infoCosts.length > 0 && infoCosts.map((item, index) => (
                                                    <div key={index} className="mt-2">
                                                        <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
                                                            <table className="min-w-full divide-y divide-gray-300">
                                                                <thead>
                                                                <tr>
                                                                    <th
                                                                        scope="col"
                                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                                                    >
                                                                        {item.title}
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                                                                    />
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {item.rows && item.rows.length > 0 && item.rows.map((row, index) => (
                                                                    <tr key={"a" + index}
                                                                        className="border-t border-gray-200">
                                                                        <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                                                            <div
                                                                                className="text-gray-900">{row[0]}</div>
                                                                            <div
                                                                                className="mt-0.5 text-gray-500 sm:hidden">
                                                                                {row[0]} at {row[1]}
                                                                            </div>
                                                                        </td>
                                                                        {/*<td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{project.hours}</td>
                                                                            <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{project.rate}</td>*/}
                                                                        <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">{row[1]}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                                {/*<tfoot>
                                                                    <tr>
                                                                        <th
                                                                            scope="row"
                                                                            colSpan={3}
                                                                            className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                                                                        >
                                                                            Subtotal
                                                                        </th>
                                                                        <th scope="row" className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">
                                                                            Subtotal
                                                                        </th>
                                                                        <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">$3,900.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th
                                                                            scope="row"
                                                                            colSpan={3}
                                                                            className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                                                                        >
                                                                            Tax
                                                                        </th>
                                                                        <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">
                                                                            Tax
                                                                        </th>
                                                                        <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">$585.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th
                                                                            scope="row"
                                                                            colSpan={3}
                                                                            className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                                                                        >
                                                                            Total
                                                                        </th>
                                                                        <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
                                                                            Total
                                                                        </th>
                                                                        <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                                                                            $4,485.00
                                                                        </td>
                                                                    </tr>
                                                                    </tfoot>*/}
                                                            </table>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
};
