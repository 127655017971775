import React, {useEffect, useState} from 'react';
import {ChevronDownIcon, ChevronUpIcon, DownloadIcon, PencilIcon} from '@heroicons/react/solid'
import {classNames, sharedAccessTokenKey, sharedFetch} from "../helpers/helpers";
import {useLogout} from "react-admin";
import {downloadStorageFile, getDateString} from "../utils";
import {INVOICE_TYPE} from "../promissory-notes/consts";

const entryActions = (d) => {
    return (
        <div className="inline-flex space-x-3">
            {d.fileId && (
                <button
                    type="button"
                    onClick={() => downloadStorageFile(d.fileId, `invoice-${d.number}.pdf`)}
                    className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-gray-500 bg-gray-200 hover:bg-gray-300"
                ><DownloadIcon className="h-3 w-3" aria-hidden="true"/></button>
            )}
            {d.hasPromissoryNote && (
                <a
                    href={"#/promissory-notes/" + d.promissoryNoteRef?._path?.segments[3] + "/invoices/" + d.id}
                    className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-gray-500 bg-gray-200 hover:bg-gray-300"
                ><PencilIcon className="h-3 w-3" aria-hidden="true"/></a>
            )}
        </div>
    )
}
const defaultColumns = [
        {v: d => d.number, label: "Invoice No.", sort: "number"},
        {v: d => d.status, label: "Status"},
        {
            v: d => d.hasPromissoryNote ? <a className="underline"
                                             href={"#promissory-notes/" + d.promissoryNoteRef?._path?.segments[3]}>{d.promissoryNoteRef?._path?.segments[3] || "undefined"}</a> : "-",
            label: "Promissory Note"
        },
        {
            v: d => d.date?._seconds ? getDateString(new Date(d.date._seconds * 1000)) : "-",
            label: "Date",
            sort: "date"
        },
        {
            v: d => d.dueDate?._seconds ? getDateString(new Date(d.dueDate._seconds * 1000)) : "-",
            label: "Due Date",
            sort: "date"
        },
        {
            v: d => d.promissoryNote?.transactionDate?._seconds ? getDateString(new Date(d.promissoryNote.transactionDate._seconds * 1000)) : "-",
            label: "Transaction Date",
            sort: "date"
        },
        {v: d => INVOICE_TYPE[d.invoiceType] || "-", label: "Type", sort: "type"},
        {
            v: d => d.issuer?.name || "-",
            label: "Issuer"
        },
        {
            v: d => d.receiver?.name || "-",
            label: "Receiver"
        },
        {v: d => d.amount?.formatted, label: "Amount", sort: "amount"},
        {v: d => d.paidAmount?.formatted, label: "Paid"},
        {v: d => entryActions(d), label: "Actions"},
    ],
    defaultOrderBy = "createdAt",
    defaultOrderByDirection = "desc"

const limit = 20;

export const InvoiceList = props => {
    const [columns, setColumns] = useState(defaultColumns)
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [orderByDirection, setOrderByDirection] = useState(defaultOrderByDirection)
    const [result, setResult] = useState({})
    const [offset, setOffset] = useState(0)
    const logout = useLogout();

    useEffect(() => {
        fetchList()
    }, [orderBy, orderByDirection, offset])

    function fetchList() {
        sharedFetch(`admin/finance/invoices?limit=${limit}&orderBy=${orderBy}&orderByDirection=${orderByDirection}&offset=${offset}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        }).then((json) => {
            console.log(json)
            setResult(json)
            //setFilterCount(json?.totalOverdue || 0)
        })
    }

    function nextPage(e) {
        //result?.offset + result?.count < result?.total
        if (result?.offset + result?.count < result?.total) setOffset(offset + limit)
    }

    function prevPage(e) {
        if (offset > 0) setOffset(offset - limit)
    }

    function sort(columnIndex) {
        return function onClick(e) {
            e.preventDefault()
            const col = columns[columnIndex]
            if (col?.sort) {
                switch (col.direction) {
                    case "asc":
                        col.direction = "desc"
                        break;
                    case "desc":
                        col.direction = null
                        break;
                    default:
                        col.direction = "asc"
                }
                if (col.direction) {
                    setOrderBy(col.sort)
                    setOrderByDirection(col.direction)
                } else {
                    setOrderBy(defaultOrderBy)
                    setOrderByDirection(defaultOrderByDirection)
                }
                setColumns(columns.map((it, i) => i === columnIndex ? {...col} : {...it, direction: null}))
            }
        }
    }

    /*function download(id) {
        return function onClick(e) {
            e.preventDefault()
            sharedFetch(`invoices/download/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(new Blob([blob]))
                    link.setAttribute('download', `upn.xml`)
                    document.body.appendChild(link)
                    link.click()
                    link.parentNode.removeChild(link)
                });
        }
    }*/

    function deleteEntry(id) {
        return function onClick(e) {
            e.preventDefault()

            const ok = window.confirm("Are you sure you wish to delete this entry?")
            if (ok) sharedFetch(`invoices/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
                }
            }).then(res => {
                fetchList()
            })
        }
    }

    return (
        <>
            <div className="my-8 space-y-6">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Invoices</h1>
                            {/*<p className="mt-2 text-sm text-gray-700">
                                A list of all the users in your account including their name, title, email and role.
                            </p>*/}
                        </div>
                        {/*<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <a
                                type="button"
                                href="#/invoices/create"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Create new
                            </a>
                        </div>*/}
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            {columns.map((col, i) => (
                                                <th key={col.label} scope="col"
                                                    className={classNames(
                                                        "text-left text-sm font-semibold text-gray-900",
                                                        i === 0 ? "py-3.5 pl-4 pr-3 sm:pl-6" : "px-3 py-3.5"
                                                    )}>
                                                    <span onClick={sort(i)} className={classNames(
                                                        "group inline-flex",
                                                        col.sort ? "cursor-pointer" : "cursor-default"
                                                    )}>
                                                        {col.label}
                                                        <span className={classNames(
                                                            col.direction ? "ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>{col.direction === "asc" ? (
                                                            <ChevronUpIcon className="h-5 w-5" aria-hidden="true"/>
                                                        ) : col.direction === "desc" ? (
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
                                                        ) : null}</span>
                                                    </span>
                                                </th>
                                            ))}
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {result?.results?.map(data => (
                                            <tr key={data.id}>
                                                {columns.map((col, i) => (
                                                    <td key={col.label}
                                                        className={classNames(
                                                            "whitespace-nowrap text-sm",
                                                            i === 0 ? "py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6" : "px-3 py-4 text-gray-500"
                                                        )}>
                                                        {col.v(data)}
                                                    </td>
                                                ))}
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    {/*<a href="#" className="text-gray-600 hover:text-gray-900">
                                                        Edit<span className="sr-only"/>
                                                    </a>*/}
                                                    {/*<button
                                                        type="button"
                                                        onClick={download(data.id)}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <DownloadIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </button>*/}
                                                    {/*<button
                                                        type="button"
                                                        onClick={deleteEntry(data.id)}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <TrashIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </button>*/}
                                                    {/*<a
                                                        href={"#/invoices/" + data.id}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <PencilIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </a>*/}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                    <nav
                                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                        aria-label="Pagination"
                                    >
                                        <div className="hidden sm:block">
                                            <p className="text-sm text-gray-700">
                                                Showing <span
                                                className="font-medium">{(result?.offset + 1) || "0"}</span> to <span
                                                className="font-medium">{result?.offset + result?.count || "0"}</span> of{' '}
                                                <span className="font-medium">{result?.total || "0"}</span> results
                                            </p>
                                        </div>
                                        <div className="flex-1 flex justify-between sm:justify-end">
                                            <button
                                                type="button"
                                                onClick={prevPage}
                                                className={classNames(
                                                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    result?.offset ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Previous
                                            </button>
                                            <button
                                                type="button"
                                                onClick={nextPage}
                                                className={classNames(
                                                    "ml-3 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    (result?.offset + result?.count < result?.total) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
