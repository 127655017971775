import React, {useState} from "react";
import {toCurrency} from "../utils";

export default function AmountInputTailwind({name, value, id, disabled, className, onChange}) {
    const [isEditing, setIsEditing] = useState(false)

    function toggleEditing() {
        setIsEditing(!isEditing)
    }

    return <>
        {isEditing ? (
            <input
                type={"text"}
                name={name}
                id={id}
                value={value}
                disabled={disabled}
                className={className}
                onChange={onChange}
                onBlur={toggleEditing}
            />
        ) : (
            <input
                type={"text"}
                name={name}
                value={toCurrency(value)}
                disabled={disabled}
                className={className}
                onFocus={toggleEditing}
                readOnly={true}
            />
        )}
    </>
};
