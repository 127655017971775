import {sharedAccessTokenKey, sharedFetch} from "./helpers/helpers";

export const daysBetween = (startDate, endDate) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.floor((endDate - startDate) / millisecondsPerDay).toFixed(0);
}
export const daysFromNow = endDate => daysBetween(Date.now(), endDate)

export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function validReferenceNumber(str) {
    if (typeof str != "string") return false // we only process strings!
    const spl = str.split("-")
    for (let i = 0; i < spl.length; i++) {
        if (!isNumeric(spl[i])) return false
    }
    return true
}

export function serializeQuery(params, prefix) {
    if (!params) return ""
    const query = Object.keys(params).map((key) => {
        const value = params[key];
        if (params.constructor === Array) key = `${prefix}[${key}]`;
        else if (params.constructor === Object) key = (prefix ? `${prefix}[${key}]` : key);
        if (typeof value === 'object') return serializeQuery(value, key);
        else return `${key}=${encodeURIComponent(value)}`;
    })
    return [].concat.apply([], query).join('&');
}

export function chunkify(array, chunkSize) {
    const chunks = [];

    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }

    return chunks;
}

export function parsePlainDate(input) {
    try {
        const parts = input?.trim().split(".")?.map(p => parseInt(p))
        return parts?.length === 3 ? `${parts[2]}-${("" + parts[1]).padStart(2, "0")}-${("" + parts[0]).padStart(2, "0")}` : null
    } catch (e) {
    }
    return null
}

/**
 * Date string in format DD.MM.YYYY
 * @param date
 */
export const getDateString = date => {
    if (!date) return null
    date = typeof date === 'string' ? new Date(date) : date
    let m = "" + (date.getMonth() + 1).toString(), d = "" + date.getDate()
    if (m.length === 1) m = "0" + m
    if (d.length === 1) d = "0" + d
    return `${d}.${m}.${date.getFullYear()}`
}


/**
 * Datetime string in format DD.MM.YYYY HH:MM
 * @param date
 */
export const getDatetimeString = date => {
    if (!date) return null
    date = typeof date === 'string' ? new Date(date) : date
    let h = "" + date.getHours(), m = "" + date.getMinutes()
    if (h.length === 1) h = "0" + h
    if (m.length === 1) m = "0" + m
    return `${getDateString(date)} at ${h}:${m}`
}

export function inputDateStringToISODateFormat(d) {
    if (!d) return null
    const date = new Date(d)
    return date.toISOString()
}

export const fireTimestampToDate = ts => {
    if (!ts || typeof ts !== "object" || !ts._seconds) return null
    return new Date(ts._seconds * 1000)
}

export const fireTimestampToDateString = ts => getDateString(fireTimestampToDate(ts))

export const fireTimestampToDateTimeString = ts => getDatetimeString(fireTimestampToDate(ts))

export const fireTimestampToDateInputString = ts => {
    const date = fireTimestampToDate(ts)
    if (!date) return ""
    let m = "" + (date.getMonth() + 1).toString(), d = "" + date.getDate()
    if (m.length === 1) m = "0" + m
    if (d.length === 1) d = "0" + d
    return `${date.getFullYear()}-${m}-${d}`
}

export function toDecimal(str) {
    return toCurrency(str, true)
}

export function toCurrency(str, leaveOutSymbol) {
    const formatter = new Intl.NumberFormat("sl-SI", {
        style: leaveOutSymbol ? "decimal" : "currency",
        currency: "EUR"
    })
    return formatter.format(("" + (str || 0)).replace(",", ".")) || "";
}

function comma(address) {
    return address.length > 0 ? ", " : ""
}

export function downloadStorageFile(fileId, fileName) {
    sharedFetch(`admin/files/${fileId}`, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
        }
    })
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([blob]))
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
        });
}

export function formatAddress(addressObject) {
    if (!addressObject) return null
    let address = addressObject.address1 || addressObject.address || addressObject.street || "",
        houseNumber = addressObject.houseNumber || "",
        postCode = addressObject.postCode || "",
        city = addressObject.city || "",
        country = addressObject.country?.name || ""

    return [[address, houseNumber].filter(a => a).join(" "), [postCode, city].filter(a => a).join(" "), country].filter(a => a).join(", ")
}

export const downloadFile = (blob, filename) => {
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([blob]))
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
}

export function clickLink(href) {
    const link = document.createElement('a');
    link.href = href //"#/promissory-notes/" + id;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export function fetchFromObject(obj, prop) {
    if (typeof obj === 'undefined') return false

    // Index of next property split
    let index = prop.indexOf('.')

    if (index > -1) {
        // Get object at property (before split), pass on remainder
        return fetchFromObject(obj[prop.substring(0, index)], prop.substring(index + 1))
    }

    // No split, just get property
    return obj[prop]
}

export function slugify(str) {
    return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
}
