import React, {useEffect, useState} from 'react';
import {classNames, sharedAccessTokenKey, sharedFetch,} from "../helpers/helpers";
import {PlusSmIcon as PlusSmIconSolid} from '@heroicons/react/solid'
import {useLogout} from "react-admin";
import {formatAddress} from "../utils";
import {ORGANIZATION_STATUS,} from "./consts";
import Spinner from "../components/spinner";
import TableView from "../components/tableView";
import ViewPartnerDialog from "./viewPartnerDialog";
import SearchCompaniesDialog from "./searchCompaniesDialog";
import parsePhoneNumber from "libphonenumber-js";

const userTableColumns = [
    {v: d => d.profile?.fullName, label: "Full Name", sort: "fullName"},
    {
        v: d => formatAddress(d.profile?.address),
        label: "Address",
        href: d => "https://maps.google.com/?q=" + encodeURIComponent(formatAddress(d.profile?.address))
    },
    {
        v: d => d.profile?.email || d.email,
        label: "Email",
        sort: "email",
        href: d => "mailto:" + (d.profile?.email || d.email || "")
    },
    {v: d => d.emailVerified === true ? "Yes" : "-", label: "Email verified"},
    {
        v: d => d.profile?.phone ? parsePhoneNumber(d.profile?.phone).formatInternational() : "",
        label: "Phone",
        href: d => "tel:" + (d.profile?.phone || "")
    },
    {v: d => d.profile?.appAccess?.join(", "), label: "Apps", sort: "appAccess", className: "capitalize"},
    {v: d => d.enrolledFactors && d.enrolledFactors.length > 0 ? "Yes" : "-", label: "2FA"},
    {v: d => d.lastSignedInAt, label: "Last signed in"},
]
const tableColumns = [
    {v: d => d.oid, label: "Company ID", sort: "oid"},
    {v: d => d.name, label: "Name", sort: "name"},
    {v: d => d.taxNo, label: "Tax Number", sort: "taxNo"},
    {v: d => formatAddress(d), label: "Address"},
    {v: d => d.status, label: "Status", sort: "status"},
]

const availableFeatures = [
    {name: "FINANCE_BETA", label: "Finance: Beta"},
    {name: "FINANCE_FACTORING", label: "Finance: Factoring"},
    {name: "FINANCE_FACTORING_CREATE", label: "Finance: Factoring [CREATE]"},
    {name: "FINANCE_REVERSE_FACTORING", label: "Finance: Reverse Factoring"},
    {name: "FINANCE_REVERSE_FACTORING_CREATE", label: "Finance: Reverse Factoring [CREATE]"},
    {name: "FINANCE_PARTNERS", label: "Finance: Partners"},
    {name: "FINANCE_CASHFLOW", label: "Finance: Cashflow"},
    {name: "FINANCE_CREDIT_CHECK", label: "Finance: Credit Check"},
    {name: "FINANCE_FINANCIAL_ANALYTICS", label: "Finance: Financial Analytics"},
    {name: "FINANCE_INTEGRATIONS", label: "Finance: Integrations"},
]

export default function Organization({match}) {
    const logout = useLogout();

    const [viewPartnerDialogOpen, setViewPartnerDialogOpen] = useState(false);
    const [partnerData, setPartnerData] = useState(null);
    const [canEdit, setCanEdit] = useState(true);
    const [id, setId] = useState(); // entry editing
    const [isLoading, setIsLoading] = useState(false); // entry editing
    const [partners, setPartners] = useState([])
    const [users, setUsers] = useState([])

    const [status, setStatus] = useState("")
    const [serverOid, setServerOid] = useState("")
    const [oid, setOid] = useState("")
    const [name, setName] = useState("")
    const [taxNo, setTaxNo] = useState("")
    const [vatNo, setVatNo] = useState("")
    const [features, setFeatures] = useState({})

    const [isSearchCompaniesDialogOpen, setIsSearchCompaniesDialogOpen] = useState(false);

    const rowActions = {
        edit: (item) => {
            /*setPartnerData(item);
            setViewPartnerDialogOpen(true);*/
            goBack("#/organizations/" + id + "/partners/" + item.id);
        }
    }

    function fetchPartners(id) {
        //setIsLoading(true)
        try {
            sharedFetch(`admin/organizations/${id}/partners`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
                },
            })
                .then((resData) => {
                    //console.log(resData)
                    //if (resData?.number) setDocumentNo(resData.number)
                    if (resData?.results) setPartners(resData.results)
                    //setIsLoading(false)
                })
        } catch (e) {
            //setIsLoading(false)
        }
    }

    function fetchUsers(id) {
        //setIsLoading(true)
        try {
            sharedFetch(`admin/organizations/${id}/users`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
                },
            })
                .then((resData) => {
                    //console.log("users", resData)
                    //if (resData?.number) setDocumentNo(resData.number)
                    if (resData?.results) setUsers(resData.results)
                    //setIsLoading(false)
                })
        } catch (e) {
            //setIsLoading(false)
        }
    }

    function fetchData(id) {
        if (id) {
            setIsLoading(true)
            try {
                sharedFetch(`admin/organizations/${id}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
                    },
                })
                    .then((resData) => {
                        //console.log(resData)
                        if (resData?.statusCode === 401) logout()

                        setServerOid(resData.type === "PERSON" ? "" : resData.organization?.oid)
                        setOid(resData.type === "PERSON" ? "" : resData.organization?.oid)
                        setTaxNo(resData.type === "PERSON" ? resData.person?.taxNo : resData.organization?.taxNo)
                        setVatNo(resData.type === "PERSON" ? resData.person?.vatNo : resData.organization?.vatNo)
                        //setRegistrationNo(resData.registrationNo)
                        //setIsVatLiable(resData.isVatLiable)
                        //setCountry(resData.country)
                        //setCity(resData.city)
                        //setPostCode(resData.postCode)
                        //setAddress1(resData.address1)
                        setName(resData.type === "PERSON" ? resData.person?.name : resData.organization?.name)
                        setStatus(resData.status)
                        setFeatures(resData.features || {})

                        setIsLoading(false)

                        fetchPartners(id)
                        fetchUsers(id)
                    });
            } catch (e) {
                console.error(e)
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        const id = match.params?.id
        setId(id)
        fetchData(id)
    }, [match.params?.id]);

    function goBack(href = "#/organizations") {
        const link = document.createElement('a');
        link.href = href;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    function prepareSubmitData(showAlerts = true) {
        return {
            status,
            oid,
            features,
        }
    }

    function onChooseCompany(company) {
        if (company?.id) setOid(company.id)
    }

    function onSubmit(e) {
        e.preventDefault();

        const object = prepareSubmitData()
        if (!object) return

        const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
            },
            body = JSON.stringify(object)

        sharedFetch(`admin/organizations${id ? ("/" + id) : ""}`, id ? {
            method: 'PUT',
            headers, body
        } : {
            method: 'POST',
            headers, body
        })
    }

    return (
        <>
            <div className="my-8 space-y-6">
                <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                    {isLoading ? (
                        <Spinner/>
                    ) : (
                        <form onSubmit={onSubmit} className="space-y-8">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                <div className="sm:mt-5 space-y-6 sm:space-y-5">
                                    <div className=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                        <label htmlFor="status"
                                               className={classNames(
                                                   "block text-sm font-medium sm:mt-px sm:pt-2",
                                                   id ? "text-gray-700" : "text-gray-300"
                                               )}>
                                            Status
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <select
                                                id="status"
                                                name="status"
                                                onChange={e => setStatus(e.target.value)}
                                                value={status}
                                                className={classNames(
                                                    "max-w-lg block focus:ring-gray-500 focus:border-gray-500 w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md border-gray-300",
                                                    //!!id ? "border-gray-300 bg-gray-50 text-gray-600" : "border-gray-300"
                                                )}
                                            >
                                                {Object.keys(ORGANIZATION_STATUS).map((status, index) => (
                                                    <option key={index}
                                                            value={status}>{ORGANIZATION_STATUS[status]}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="oid"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Company ID
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2 flex">
                                            <input
                                                type="text"
                                                id="oid"
                                                value={oid}
                                                onChange={e => setOid(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                            <button
                                                type="button"
                                                onClick={() => setIsSearchCompaniesDialogOpen(true)}
                                                className="ml-4 text-sm underline text-gray-500 hover:text-gray-900"
                                            >Select company
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="name"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Name
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="name"
                                                required
                                                value={name}
                                                disabled={true}
                                                onChange={e => setName(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    true ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="taxNo"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Tax number
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="taxNo"
                                                required
                                                value={taxNo}
                                                disabled={true}
                                                onChange={e => setTaxNo(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    true ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="features"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Features
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <fieldset className="space-y-5">
                                                <legend className="sr-only">Features</legend>
                                                {availableFeatures.map(({label, name}, index) => (
                                                    <div key={index} className="relative flex items-start">
                                                        <div className="flex h-5 items-center">
                                                            <input
                                                                id={name}
                                                                name={name}
                                                                checked={features[name]}
                                                                onChange={e => setFeatures({
                                                                    ...features,
                                                                    [name]: e.target.checked
                                                                })}
                                                                type="checkbox"
                                                                className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label htmlFor={name} className="font-medium text-gray-700">
                                                                {label}
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </fieldset>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="pt-12 flex items-center">
                                <h4 className="text-lg leading-6 font-medium text-gray-900">Users</h4>
                                {/*<button
                                type="button"
                                onClick={() => fetchInfoCosts()}
                                className="ml-4 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                            >
                                <RefreshIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>*/}
                            </div>

                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <TableView columns={userTableColumns} data={users} hasMore={false}/>
                            </div>


                            <div className="pt-12 flex items-center">
                                <h4 className="text-lg leading-6 font-medium text-gray-900">Partners</h4>
                                {serverOid && (
                                    <>
                                        <button
                                            type="button"

                                            className="ml-4 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                        >
                                            <PlusSmIconSolid className="h-5 w-5" aria-hidden="true"/>
                                        </button>
                                    </>
                                )}
                                {/*<button
                                type="button"
                                onClick={() => fetchInfoCosts()}
                                className="ml-4 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                            >
                                <RefreshIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>*/}
                            </div>

                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <TableView columns={tableColumns} data={partners} hasMore={false}
                                           rowActions={rowActions}/>
                            </div>

                            <div className="pt-5">
                                <div className="flex justify-end">
                                    <a
                                        href="#/promissory-notes"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    >
                                        Cancel
                                    </a>
                                    <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    >
                                        {id ? "Update" : "Create"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            <ViewPartnerDialog data={partnerData} open={viewPartnerDialogOpen} setOpen={setViewPartnerDialogOpen}/>
            <SearchCompaniesDialog onSubmit={onChooseCompany} open={isSearchCompaniesDialogOpen}
                                   setOpen={setIsSearchCompaniesDialogOpen}/>
        </>
    )
};
