import React, {useState, useRef, useEffect} from 'react';
import {useField, useForm} from "react-final-form";
import {List, Datagrid, TextField, FunctionField, DateField, NumberField, Edit, SimpleForm, Button, Create, useRefresh, useLogout, showNotification} from 'react-admin';
import {useDispatch} from 'react-redux';
import CheckIcon from "@material-ui/icons/Check";
import {makeStyles} from "@material-ui/styles";
import TextInput from "../components/TextInput";
import AmountInput from "../components/AmountInput";
import LabeledDateInput from "../components/LabeledDateInput";
import UserLookup from "../components/UserLookup";
import UtcDateField from "../components/utcDateField";
import SearchFilter from "../components/searchFilter";
import {SimpleDialog} from "../components/SimpleDialog";
import * as Config from "../config";
import {httpClient, properDecimalNumber, useFocus, zeroPad} from "../helpers/helpers";

const amountOptions = { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 };

const useStyles = makeStyles({
    button: {
        padding: '0.75em',
        marginTop: '1em',
        marginBottom: '1em',
    },
});

function getAccountNumber(value) {
    const bankAccount = value ? value.bankAccounts.find(i => i.main) : null
    return bankAccount ? bankAccount.accountNumber : '';
}

export const TransactionList = props => (
    <List {...props} filters={<SearchFilter />} sort={{ field: 'date', order: 'DESC' }} bulkActionButtons={false} exporter={false}>
        <Datagrid rowClick="edit">
            <UtcDateField record={props.record} source="date" />
            <FunctionField render={record => record ? zeroPad(record.bankAccount.userDetails.reference.number, 6) : ''} label="#" />
            <TextField source="bankAccount.userDetails.humanFirstName" sortBy="bankAccount.user.details.firstname" label="First name" />
            <TextField source="bankAccount.userDetails.humanLastName" sortBy="bankAccount.user.details.lastname" label="Last name" />
            <FunctionField render={record => record && record.bankAccount.userDetails.company ? record.bankAccount.userDetails.company.name : '-'} label="Company name" />
            <TextField source="bankAccount.number" label="Bank account" />
            <TextField source="type.name" label="Type" />
            <NumberField source="amount" options={amountOptions} />
            <TextField source="status.name" label="Status" />
        </Datagrid>
    </List>
);

const TransactionEditTitle = ({ record }) => {
    return <span>{record ? `${record.type.name}: ${record.bankAccount.userDetails.humanFirstName} ${record.bankAccount.userDetails.humanLastName}` : ''}</span>;
};

const TransactionConfirmButton = ({ record, onConfirm }) => {
    const classes = useStyles();

    if (record.status.name === 'Processed') {
        return null;
    } else {
        return <Button variant="contained" startIcon={<CheckIcon/>} label="Mark as processed" className={classes.button} onClick={onConfirm}/>;
    }
};

export const TransactionEdit = props => {
    const refresh = useRefresh();
    const logout = useLogout();

    const handleConfirm = () => {
        // Confirm transaction
        (async () => {
            try {
                await httpClient(`${Config.ADMIN_API_URL}/transactions/${props.id}/confirm`, {
                    method: 'put',
                    mode: 'cors',
                    cache: 'no-cache',
                });
                refresh();
            } catch (e) {
                if (e.status === 401) {
                    await logout();
                }
                console.log(e.body || e);
            }
        }) ()
    }

    return (
        <Edit {...props} title={<TransactionEditTitle />}>
            <SimpleForm toolbar={false}>
                <TextField source="id" label="Transaction ID" />
                <DateField source="date" locales="sl-SI" options={{ year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }} />
                <FunctionField render={record => record ? ("000000" + record.bankAccount.userDetails.reference.number).slice (-6) : ''} label="Investor ID" />
                <FunctionField render={record => record ? `${record.bankAccount.userDetails.humanFirstName} ${record.bankAccount.userDetails.humanLastName}` : ''} label="Name" />
                <TextField source="bankAccount.userDetails.telephone" label="Phone" />
                <TextField source="bankAccount.userDetails.email" label="Email" />
                <TextField source="bankAccount.number" label="Bank account" />
                <TextField source="type.name" label="Type" />
                <NumberField source="amount" options={amountOptions} />
                <TransactionConfirmButton onConfirm={handleConfirm} />
            </SimpleForm>
        </Edit>
    );
}

export const TransactionCreate = props => {
    const logout = useLogout();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [inputRef, setInputFocus] = useFocus();
    const [bankAccountInputRef, setBankAccountFocus] = useFocus();

    const handleSearch = searchData => {
        if (!searchData) return;

        setLoading(true);

        // Search user
        (async () => {
            try {
                let response = await httpClient(`${Config.ADMIN_API_URL}/customer/search?q=${searchData}`);

                if (response.json.length > 1) {
                    setItems(response.json);
                    setOpen(true);
                } else if (response.json.length === 1) {
                    const value = response.json[0];
                    const accountNumber = getAccountNumber(value)
                    setBankAccountDisabled(!!accountNumber);
                    setSelectedValue(value);
                    selectedValueRef.current = value;
                    accountNumber ? setInputFocus() : setBankAccountFocus();
                } else {
                    setSelectedValue(null);
                    selectedValueRef.current = null;
                    dispatch(showNotification('No account found', 'warning'));
                }
            } catch (e) {
                if (e.status === 401) {
                    await logout();
                }
                console.log(e.body || e);
            }

            setLoading(false);
        }) ();
    }

    const [open, setOpen] = useState(false);
    const [bankAccountDisabled, setBankAccountDisabled] = useState(true);
    const [selectedValue, setSelectedValue] = useState(null);
    const [items, setItems] = useState([]);
    const selectedValueRef = useRef();

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
        selectedValueRef.current = value;

        /*
        id != null && number != null  =>  disabled
        id != null && number == null  =>  enabled
        id == null && number != null  =>  disabled
        id == null && number == null  =>  disabled
         */
        const shouldEnable = value && !getAccountNumber(value);
        setBankAccountDisabled(!shouldEnable);

        setTimeout(() => {
            shouldEnable ? setBankAccountFocus() : setInputFocus()
        }, 300)
    }

    const transform = data => {
        const value = selectedValueRef.current;

        data.date = data.date + 'T00:00:00.000Z'
        data.amount = properDecimalNumber(data.amount);

        return ({
            ...data,
            id: value ? value.Id : null,
        })
    }

    return (
        <Create {...props} transform={transform}>
            <SimpleForm variant="outlined">
                <UserLookup onSearch={handleSearch} loading={loading} />

                <TransactionForm
                    selectedValue={selectedValue}
                    bankAccountDisabled={bankAccountDisabled}
                    bankAccountInputRef={bankAccountInputRef}
                    inputRef={inputRef}
                    {...props} />

                <SimpleDialog open={open} onClose={handleClose} items={items} />
            </SimpleForm>
        </Create>
    );
}

const TransactionForm = ({selectedValue, bankAccountDisabled, bankAccountInputRef, inputRef, ...props}) => {
    const form = useForm()

    useEffect(() => {
        form.change('bankAccountNumber', undefined)
    }, [form, selectedValue])

    return (
        <div className="flex flex-col">
            <TextInput disabled value={selectedValue ? ("000000" + selectedValue.Id).slice (-6) : '' } label="Investor ID" {...props}/>
            <BankAccountInputField disabled={bankAccountDisabled} inputRef={bankAccountInputRef} value={getAccountNumber(selectedValue)} {...props}/>
            <TextInput disabled value={selectedValue ? `${selectedValue.contact.firstName} ${selectedValue.contact.lastName}` : ''} label="Name" {...props}/>
            <TextInput disabled value={selectedValue?.contact?.telephone} label="Phone" {...props}/>
            <TextInput disabled value={selectedValue?.contact?.email} label="Email" {...props}/>
            <LabeledDateInput label="Date" source="date" {...props}/>
            <AmountInput source="amount" label="Amount" inputRef={inputRef} {...props}/>
        </div>
    )
}

const BankAccountInputField = props => {
    const { input } = useField("bankAccountNumber")

    return (
        <TextInput
            disabled={props.disabled}
            inputRef={props.inputRef}
            label="Bank account"
            value={props.value}
            input={input}
            {...props} />
    )
}
