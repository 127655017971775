import * as Config from '../config'
import {sharedAccessTokenKey} from "../helpers/helpers";

export const imageDownloadAction = (userId, index, imageName) => {
    const url = `${Config.ADMIN_API_URL}/user_documents_2/download/${userId}?index=${index}`;

    fetch(url, {
        method: 'get',
        headers: {
            "Accept": "application/octet-stream",
            "Authorization": `Bearer ${localStorage.getItem(sharedAccessTokenKey)}`,
        },
    }).then(res => {
        if (res.status === 200) return res.blob();
        else return Promise.reject();
    }).then(blob => {
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = URL.createObjectURL(blob);
        a.download = imageName;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    }).catch(error => {
        console.error(error)
    })
};