import {initializeApp} from "firebase/app";
import {getToken, initializeAppCheck, ReCaptchaEnterpriseProvider} from 'firebase/app-check';
import {AUTH_ADMIN_TENANT_ID, ENVIRONMENT, RECAPTCHA} from "./config";
import {getAuth, reauthenticateWithCredential} from "firebase/auth";

const moveFirebaseConfig = ENVIRONMENT === "dev" ? {
    apiKey: "AIzaSyBSxsdrPMeozfLmKuHpO1zPXcLi-4OWXiE",
    authDomain: "nekster-dev.firebaseapp.com",
    projectId: "nekster-dev",
    storageBucket: "nekster-dev.appspot.com",
    messagingSenderId: "579662412670",
    appId: "1:579662412670:web:148e579bbee0d3246d912f"
} : ENVIRONMENT === "test" ? {
    apiKey: "AIzaSyBYxOKjPH9AO2HVeEv5QWlJl7zimbPfI8w",
    authDomain: "nekster-test.firebaseapp.com",
    projectId: "nekster-test",
    storageBucket: "nekster-test.appspot.com",
    messagingSenderId: "239734218617",
    appId: "1:239734218617:web:894b9a08aa276e1145d9a8"
} : ENVIRONMENT === "prod" ? {
    apiKey: "AIzaSyBPvgRHJFSbEWZek0KFU-T88nxWh39rmTc",
    authDomain: "nekster-prod.firebaseapp.com",
    projectId: "nekster-prod",
    storageBucket: "nekster-prod.appspot.com",
    messagingSenderId: "262645656552",
    appId: "1:262645656552:web:ebf749ba2a81c0c5fbd25d"
} : {}

// Initialize firebase instance
export const firebaseApp = initializeApp(moveFirebaseConfig);
const _auth = getAuth(firebaseApp);
_auth.tenantId = AUTH_ADMIN_TENANT_ID || null
export const firebaseAuth = _auth;
export const appCheck = initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaEnterpriseProvider(RECAPTCHA),
        isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
    } // ReCaptchaV3Provider or CustomProvider
);

export const callApiWithAppCheck = async (options, configObj) => {
    let appCheckTokenResponse;
    try {
        appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
    } catch (err) {
        // Handle any errors if the token was not retrieved.
        console.log("app check error", err)
        return;
    }

    //financeApi.defaults.headers.common['X-Firebase-AppCheck'] = appCheckTokenResponse.token;
    //api.defaults.headers.common['X-Firebase-AppCheck'] = appCheckTokenResponse.token;

    if (options) options.headers.set('X-Firebase-AppCheck', appCheckTokenResponse?.token);
    if (configObj) configObj.headers['X-Firebase-AppCheck'] = appCheckTokenResponse?.token;
};

export function reauth() {


    reauthenticateWithCredential()
}

//export default firebase
