import React, {Fragment, useState} from 'react';
import {PlayIcon} from '@heroicons/react/solid'
import {classNames, sharedAccessTokenKey, sharedFetch} from "../helpers/helpers";

const defaultColumns = [
    {
        v: d => d.name,
        label: "Action",
    },

    {
        v: d => d.description,
        label: "Description"
    },
]

const results = [
    {
        name: "Rebuild Search Index",
        description: "Clears and rebuilds Meilisearch indexes (promissory notes)",
    }
]

export const AdminActions = () => {
    const [columns, setColumns] = useState(defaultColumns)
    const [result, setResult] = useState({results})

    async function runRebuildIndexes() {

        if (!window.confirm('Are you sure you wish to run "Rebuild Search Index"?')) return;


        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
        }

        await sharedFetch(`admin/finance/actions/run-rebuild-indexes`, {
            method: 'GET',
            headers
            //headers, body: JSON.stringify({invoices: chunk})
        })
            .then((r) => {
                alert("Action is being run in the background!")
            })
            .catch(e => {
                if (e?.message) {
                    alert("Error: " + e.message)
                } else {
                    alert("There was an error!")
                }
                throw e
            }).finally(() => {

            })
    }

    return (
        <>
            <div className="my-8 space-y-6">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="inline-flex text-xl font-semibold text-gray-900">Admin Actions</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of actions that run on Shared-BE.
                            </p>
                            <div className="sm:px-8 inline-flex">
                                {/*<button
                                    type="button"
                                    onClick={toggleFilter}
                                    className={classNames(
                                        "inline-flex rounded-full items-center py-1 text-sm font-medium box-border border-2",
                                        isFilterOn ? "pl-1 pr-1 border-current" : "pr-2.5 pl-1 border-transparent",
                                        filterCount > 0 ? "bg-red-100 text-red-700" : "bg-gray-100 text-gray-700"
                                    )}>
                                    <div
                                        className={classNames(
                                            "flex-shrink-0 mr-1 h-5 rounded-full inline-flex items-center justify-center",
                                            filterCount > 0 ? "text-red-400 bg-red-50" : "text-gray-400 bg-gray-50"
                                        )}
                                    >
                                        <span className="sr-only">Filter count</span>
                                        <span className="px-1">{filterCount}</span>
                                    </div>
                                    Filtered
                                </button>*/}
                            </div>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            {/*<button
                                type="button"
                                onClick={() => setIsCreateAdminDialogOpen(true)}
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Create new
                            </button>*/}
                            {/*<a
                                type="button"
                                href="#/shared-users/create"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Create new
                            </a>*/}
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            {columns.map((col, i) => (
                                                <th key={col.label} scope="col"
                                                    className={classNames(
                                                        "text-left text-sm font-semibold text-gray-900",
                                                        i === 0 ? "py-3.5 pl-4 pr-3 sm:pl-6" : "px-3 py-3.5"
                                                    )}>
                                                    <span className={classNames(
                                                        "group inline-flex truncate",
                                                        col.sort ? "cursor-pointer" : "cursor-default"
                                                    )}>
                                                        {col.label}
                                                    </span>
                                                </th>
                                            ))}
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {result?.results?.map((data, index) => (
                                            <tr key={index}>
                                                {columns.map((col, i) => (
                                                    <td key={col.label}
                                                        className={classNames(
                                                            i === 0 ? "py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6" : "px-3 py-4 text-gray-500"
                                                        )}>
                                                        {col.href ? (
                                                            <a
                                                                className={classNames(
                                                                    col.className || "",
                                                                    "p-0 whitespace-nowrap text-sm truncate hover:text-gray-900 underline",
                                                                )}
                                                                rel="noreferrer"
                                                                target="_blank"
                                                                href={col.href(data)}>
                                                                {col.v(data)}
                                                            </a>
                                                        ) : (
                                                            <span
                                                                className={classNames(
                                                                    col.className || "",
                                                                    "p-0 whitespace-nowrap text-sm truncate",
                                                                )}>
                                                                {col.v(data)}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <button
                                                        onClick={() => runRebuildIndexes()}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <PlayIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </button>
                                                    {/*
                                                    <a
                                                        href={"#/shared-users/" + data.uid}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <PencilIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </a>*/}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                    <nav
                                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                        aria-label="Pagination"
                                    >
                                        <div className="hidden sm:block">
                                            <p className="text-sm text-gray-700">
                                                Showing <span
                                                className="font-medium">{(result?.offset + 1) || "0"}</span> to <span
                                                className="font-medium">{result?.offset + result?.count || "0"}</span> results
                                            </p>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
