import React, {useEffect, useState} from "react"
import {BooleanInput, Button} from "react-admin"
import {useField} from "react-final-form";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core"
import {StatsList} from "../dashboard/StatsList"
import TextInput from "../components/TextInput"
import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import CancelIcon from "@material-ui/icons/Cancel"
import {makeStyles} from "@material-ui/styles"

const useStyles = makeStyles({
    button: {
        padding: '0.5em',
    },
})

const columns = [
    { id: 'accountNumber', label: 'Account number', minWidth: 100 },
    { id: 'bank', label: 'Bank name', minWidth: 100 },
    { id: 'swift', label: 'BIC/SWIFT', minWidth: 100 },
    { id: 'main', label: 'Default', minWidth: 70, align: 'center', format: (value) => value ? '\u2714' : '' },
]

export const BankAccountsList = props => {
    const { input: bankAccounts } = useField("bankAccounts")

    const [items, setItems] = useState(undefined)
    const [canEdit, setCanEdit] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)

    useEffect(() => {
        setItems(bankAccounts.value)
    }, [bankAccounts])

    useEffect(() => {
        const data = Object.keys(props.record).length ? props.record : props.companyData
        setCanEdit(data?.autoData?.hasBankAccountData === false)
    }, [props])

    useEffect(() => {
        if (!showDialog) {
            // To avoid visual glitch of switching from edit to add dialog when closing edit dialog
            setTimeout(() => {
                setSelectedIndex(null)
            }, 500)
        }
    }, [showDialog]);

    const handleRowClick = rowNo => {
        setSelectedIndex(rowNo)
        setShowDialog(true)
    }

    const handleAddClick = () => {
        setShowDialog(true)
    }

    const handleCloseClick = () => {
        setShowDialog(false)
    }

    const handleSubmit = event => {
        event.preventDefault()

        const formData = new FormData(event.target)
        const payload = {
            accountNumber: formData.get('accountNumber'),
            bank: formData.get('bank'),
            swift: formData.get('swift'),
            main: formData.get('main') !== null,
        }

        let updatedItems = bankAccounts.value

        // If updated or added account is set as default, we need to reset current default, if any
        if (payload.main) {
            updatedItems = updatedItems.map(item => ({...item, main: false}))
        }

        switch (selectedIndex) {
            case null: // Adding account
                updatedItems.push(payload)
                break
            default: // Editing account
                updatedItems[selectedIndex] = payload
        }

        bankAccounts.onChange(updatedItems)

        setShowDialog(false)
    }

    const handleDelete = () => {
        if (selectedIndex !== null) {
            const updatedItems = bankAccounts.value
            updatedItems.splice(selectedIndex, 1)
            bankAccounts.onChange(updatedItems)
        }

        setShowDialog(false)
    }

    return (
        <div>
            <StatsList
                columns={columns}
                items={items}
                hidePagination={true}
                titleBar={<TableHeader showAdd={canEdit} onClick={handleAddClick} />}
                onRowClick={handleRowClick} />

            <AccountDialog
                variant="outlined"
                show={showDialog}
                account={selectedIndex !== null ? items?.[selectedIndex] : null}
                canEdit={canEdit}
                onSubmit={handleSubmit}
                onClose={handleCloseClick}
                onDelete={handleDelete} />
        </div>
    )
}

const TableHeader = ({showAdd = false, ...props}) => {
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{padding: '0.7em'}}><h2>Bank accounts</h2></div>
            {showAdd && <div style={{marginLeft: 'auto', paddingRight: '0.7em'}}><AddBankAccountButton {...props} /></div>}
        </div>
    )
}

const AddBankAccountButton = props => {
    const classes = useStyles();

    return (
        <Button startIcon={<AddIcon />} label="Add" className={classes.button} onClick={props.onClick} />
    );
}

const AccountDialog = ({ show, account = null, canEdit = true, ...props}) => {
    const classes = useStyles()

    const isEditing = account != null && canEdit
    const isViewing = account != null && !canEdit
    const saveEnabled = account != null

    const { input: accountNumber } = useField("accountNumber")
    const { input: bank } = useField("bank")
    const { input: swift } = useField("swift")
    const { input: main } = useField("main")

    useEffect(() => {
        accountNumber.onChange(account?.accountNumber || '')
        bank.onChange(account?.bank || '')
        swift.onChange(account?.swift || '')
        main.onChange(account?.main || false)
    }, [account])

    return (
        <Dialog fullWidth open={show} onClose={props.onClose}>
            <form onSubmit={props.onSubmit}>
                <DialogTitle>{isEditing ||isViewing ? 'Edit' : 'Add new'} bank account</DialogTitle>
                <DialogContent>
                    <TextInput
                        readonly={isViewing}
                        label="Account number"
                        input={accountNumber}
                        {...props}/><br/>
                    <TextInput
                        readonly={isViewing}
                        label="Bank name"
                        input={bank}
                        {...props}/><br/>
                    <TextInput
                        readonly={isViewing}
                        label="BIC/SWIFT"
                        input={swift}
                        {...props}/><br/>
                    <BooleanInput
                        label="Default account"
                        source="main"
                        helperText={false}/>
                </DialogContent>
                <DialogActions>
                    {isEditing && <Button label="ra.action.delete" onClick={props.onDelete}><DeleteIcon /></Button>}
                    <div style={{ flexGrow: 1 }} />
                    {!saveEnabled && <Button variant="contained" label="Add" className={classes.button} type="submit"><AddIcon /></Button>}
                    {saveEnabled && <Button variant="contained" label="Save" className={classes.button} type="submit"><EditIcon /></Button>}
                    <Button label="ra.action.cancel" onClick={props.onClose}><CancelIcon /></Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default BankAccountsList;