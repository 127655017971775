import {TextInput, useLogout} from "react-admin";
import React, {useState} from "react";
import {httpClient} from "../helpers/helpers";
import * as Config from "../config";

const UserComments = ({ value = '', record = {}, source, onChange }) => {
    const logout = useLogout();

    const [needsFetch, setNeedsFetch] = useState(true)

    const handleChange = event => {
        onChange(event.target.value)
    }

    async function getNotes() {
        try {
            let response = await httpClient(`${Config.ADMIN_API_URL}/customer/${record.id}/notes`);
            onChange(response.json.notes);
        } catch (e) {
            if (e.status === 401) {
                await logout();
            }
            console.log(e.body || e);
        }
    }

    if (needsFetch) {
        setNeedsFetch(false);
        getNotes();
    }

    return <TextInput record={record} source={source} value={value} label="Comments" options={{ multiline: true }} fullWidth rows={10} onChange={handleChange} />
};

export default UserComments;