import React from 'react';
import {List, Datagrid, TextField, NumberField} from 'react-admin';

const amountOptions = { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 };

export const InvestmentsByDateList = props => (
    <List {...props} sort={{ field: null, order: null }} bulkActionButtons={false}>
        <Datagrid>
            <NumberField source="year" sortable={false} />
            <NumberField source="month" sortable={false} />
            <TextField source="monthName" sortable={false} />
            <NumberField source="amount" sortable={false} options={amountOptions} />
            <NumberField label="Amount PG" source="amountPg" sortable={false} options={amountOptions} />
            <NumberField label="Amount BG" source="amountBg" sortable={false} options={amountOptions} />
        </Datagrid>
    </List>
);